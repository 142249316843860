import React from "react";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { FromDateStrToFLADateStr } from "../../Utils/FormatDateValues";
/** MUI Imports */
import {IconButton, Grid, CircularProgress, Tooltip} from "@material-ui/core";
import {
  Edit as EditIcon,
  ListAlt as ListAltIcon,
  Warning as WarningIcon,
  Info as InfoIcon
} from "@material-ui/icons";
/** Custom Styles **/
import { useStyles } from "./LeaveAccruedTaken.styles";

import { useTranslation } from "react-i18next";
import { LeaveActions, InvalidReasons } from "../../constants";
/** Service Call **/
import { getLeaves } from "../../services/leaveAccrualTakenService";
import { CreateEditViewLeaveActions } from "./LeaveActions/CreateEditViewLeaveActions";
import { FormatDecimalValues } from "../../Utils/FormatNumericValues";
import { LeaveRemarksDialog } from "./LeaveActions/LeaveRemarksDialog";

//Function to create the Leave panel
export const Leave = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const leaveHistoryLabels = "LeaveAccruedTaken.leaveHistoryLabels";
  /* Logic to call the service again, when new data is added from Leave Action Dialog */
  const [leaves, setLeaves] = React.useState([]);
  const {
    universityId,
    reloadLeave,
    setReloadLeave,
    setReloadAudit,
    setReloadAccrual,
    setPageError,
    setAlert,
    clearAlert,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  /** Call to service to fetch Leaves */
  React.useEffect(() => {
    if (reloadLeave || !leaves.length) {
      getLeaves(
        setLeaves,
        setLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
      setReloadLeave(false);
    }
  }, [
    universityId,
    reloadLeave,
    setReloadLeave,
    leaves,
    clearAlert,
    setAlert,
    setPageError,
  ]);
  const [showEditLeaveActionDialog, setShowEditLeaveActionDialog] =
    React.useState(false);
  const [actionType, setActionType] = React.useState("");
  const [leaveId, setLeaveId] = React.useState("");

  const [showLeaveRemarksDialog, setShowLeaveRemarksDialog] = React.useState(false);
  const [leave, setLeave] = React.useState({});

  const openLeaveActionDialog = (leaveId, actionType) => {
    setShowEditLeaveActionDialog(true);
    setActionType(actionType);
    setLeaveId(leaveId);
  };

  const openLeaveRemarksDialog = (leave) => {
    setShowLeaveRemarksDialog(true);
    setLeave(leave);
  }

  const formatPercentageOnDuty = (pod) => {
    if (pod == null) {
      return "";
    }
    return `${FormatDecimalValues(pod)}%`;
  };

  const formatInvalidReasons = (invalidReasons) => {
    return invalidReasons.map((anObjectMapped, index) => {
      return (
        <div key={index}>
          {t(InvalidReasons[anObjectMapped])}
          <br />
        </div>
      );
    });
  };

  const columnData = [
    {
      id: "title",
      value: t(`${leaveHistoryLabels}.leaveType`),
      width: "20%",
    },
    {
      id: "dataSource",
      value: t(`${leaveHistoryLabels}.leaveDataSource`),
      width: "10%",
    },
    {
      id: "startDate",
      value: t(`${leaveHistoryLabels}.leaveStart`),
      width: "10%",
    },
    {
      id: "endDate",
      value: t(`${leaveHistoryLabels}.leaveEnd`),
      width: "10%",
    },
    {
      id: "percentageOnDuty",
      value: t(`${leaveHistoryLabels}.percOnDuty`),
      width: "15%",
    },
    {
      id: "actionId",
      value: t(`${leaveHistoryLabels}.actionId`),
      width: "8%",
    },
    {
      id: "actionState",
      value: t(`${leaveHistoryLabels}.actionState`),
      width: "14%",
    },
    {
      id: "actionIcons",
      value: t("globals.labels.actions"),
    },
  ];
  const rowData = leaves.map((i) => {
    return {
      title: { value: i.title },
      dataSource: { value: i.dataSource },
      status: { value: "TBD" },
      startDate: {
        value: FromDateStrToFLADateStr(i.startDate),
      },
      endDate: {
        value: FromDateStrToFLADateStr(i.endDate),
        segmentIcon: !i.valid && (
          <Tooltip title={formatInvalidReasons(i.invalidReasons)}>
            <WarningIcon
              fontSize="small"
              classes={{ root: classes.warningIcon }}
            />
          </Tooltip>
        ),
        rowReverse: !i.valid && true,
      },
      percentageOnDuty: { value: formatPercentageOnDuty(i.percentageOnDuty) },
      actionId: {
        value: i.actionId,
      },
      actionState: { value: t(LeaveActions[i.actionState]) },
      actionIcons: {
        value: <React.Fragment>
          <Grid container>
            <Grid item xs={4} lg={2} xl={1}>
              {i.actionId && i._links.editLeave && <IconButton
                  aria-label="edit"
                  classes={{root: classes.iconButtons}}
                  onClick={() => openLeaveActionDialog(i.actionId, "edit")}
                >
                  <EditIcon fontSize="small"/>
                </IconButton>}
            </Grid>
            <Grid item xs={4} lg={2} xl={1}>
              {i.actionId && i._links.viewLeave && <IconButton
                  aria-label="view"
                  classes={{root: classes.iconButtons}}
                  onClick={() => openLeaveActionDialog(i.actionId, "view")}
                >
                  <ListAltIcon fontSize="small"/>
                </IconButton>}
            </Grid>
            <Grid item xs={4} lg={2} xl={1}>
              {i.remarks && <IconButton
                  aria-label="remarks"
                  classes={{root: classes.iconButtons}}
                  onClick={() => openLeaveRemarksDialog(i)}
                >
                  <InfoIcon fontSize="small"/>
                </IconButton>}
            </Grid>
          </Grid>
        </React.Fragment>,
      },
    };
  });
  return (
    (loading && (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    )) || (
      <React.Fragment>
        <AccordionSummaryTable columnData={columnData} rowData={rowData} />
        <CreateEditViewLeaveActions
          actionType={actionType}
          leaveId={leaveId}
          universityId={universityId}
          setShowEditLeaveActionDialog={setShowEditLeaveActionDialog}
          showEditLeaveActionDialog={showEditLeaveActionDialog}
          setReloadLeave={setReloadLeave}
          setReloadAudit={setReloadAudit}
          setReloadAccrual={setReloadAccrual}
        />
        <LeaveRemarksDialog
          setShowDialog={setShowLeaveRemarksDialog}
          showDialog={showLeaveRemarksDialog}
          leave={leave}
        />
      </React.Fragment>
    )
  );
};
