import React from "react";
/* Custom Component */
import { FromDateStrToFLADateStr } from "../../Utils/FormatDateValues";
/** Material UI Imports **/
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableSortLabel,
  TablePagination,
  Typography,
  Paper,
  Tooltip,
  Checkbox,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useStyles } from "./FacultyLeave.styles";
import { getFacultyLeaveList } from "../../services/facultyLeaveService";
import { useTranslation } from "react-i18next";
import { useFacultyLeaveContext } from "../../services/facultyLeaveContext";
/* Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

const EnhancedTableHead = (props) => {
  const [checked, setChecked] = React.useState(false);
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleCheckAll(event.target.checked);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          key="checkbox"
          padding="checkbox"
          classes={{ root: classes.hide }}
        >
          <Checkbox
            disableRipple={true}
            checked={checked}
            onChange={handleChange}
            size="medium"
            color="primary"
          />
        </TableCell>
        <TableCell
          align="left"
          key="name"
          sortDirection={orderBy === "name" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "name"}
            onClick={createSortHandler("name")}
            direction={orderBy === "name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.name")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="title"
          sortDirection={orderBy === "title" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "title"}
            onClick={createSortHandler("title")}
            direction={orderBy === "title" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.rank")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="facultyLine"
          sortDirection={orderBy === "facultyLine" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "facultyLine"}
            onClick={createSortHandler("facultyLine")}
            direction={orderBy === "facultyLine" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.line")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="department.name"
          sortDirection={orderBy === "department.name" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "department.name"}
            onClick={createSortHandler("department.name")}
            direction={orderBy === "department.name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.department")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="startDate"
          sortDirection={orderBy === "startDate" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "startDate"}
            onClick={createSortHandler("startDate")}
            direction={orderBy === "startDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptStart")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="endDate"
          sortDirection={orderBy === "endDate" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "endDate"}
            onClick={createSortHandler("endDate")}
            direction={orderBy === "endDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptEnd")}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="activeFlag"
          sortDirection={orderBy === "activeFlag" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "activeFlag"}
            onClick={createSortHandler("activeFlag")}
            direction={orderBy === "activeFlag" ? order : "asc"}
          >
            {t("FLA.filterPanel.options.activeInactive.label")}
          </TableSortLabel>
        </TableCell>
        <TableCell align="left" key="actions">
          {t("FLA.mainView.list.tableColumns.actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const FacultyLeave = ({ filterData, history }) => {
  const [checked, setChecked] = React.useState([]);
  const { facultyLeaveFiltersPreference, pagination, setPagination } =
    useFacultyLeaveContext();
  const [facultyLeaveList, setFacultyLeaveList] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlertContext();
  // To use the makeStyles
  const classes = useStyles();
  React.useEffect(() => {
    getFacultyLeaveList(
      setFacultyLeaveList,
      setLoading,
      facultyLeaveFiltersPreference,
      pagination.page,
      rowsPerPage,
      orderBy,
      order,
      history,
      setAlert,
      clearAlert
    );
  }, [
    facultyLeaveFiltersPreference,
    pagination.page,
    rowsPerPage,
    orderBy,
    order,
    history,
    setAlert,
    clearAlert,
  ]);

  const handleCheckAll = (checkedAll) => {
    let checkAll = facultyLeaveList.values.map((data, index) => {
      return index;
    });
    setChecked(checkedAll ? checkAll : []);
  };

  const handleCheck = (event) => {
    let eventValue = parseInt(event.target.value);
    setChecked(
      checked.includes(eventValue)
        ? checked.filter((c) => c !== eventValue)
        : [...checked, eventValue]
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPagination({
      ...pagination,
      page: 0,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.facultyLeaveListWrapper }}
    >
      {alert.exists && (
        <Grid item>
          <ASAlert />
        </Grid>
      )}
      <Grid container item>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" stickyHeader>
            <EnhancedTableHead
              handleCheckAll={handleCheckAll}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan="10" align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                facultyLeaveList.values &&
                facultyLeaveList.values.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" classes={{ root: classes.hide }}>
                      <Checkbox
                        checked={checked.includes(index)}
                        disableRipple={true}
                        size="medium"
                        onChange={handleCheck}
                        value={index}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2">
                              {t("FLA.mainView.list.tableColumns.name")}
                              {": "}
                              {row.primaryName}
                            </Typography>
                            <Typography variant="body2">
                              {t(
                                "FLA.mainView.list.tooltips.name.text.universityID"
                              )}
                              {": "}
                              {row.universityId}
                            </Typography>
                            <Typography variant="body2">
                              {t(
                                "FLA.mainView.list.tooltips.name.text.sunetID"
                              )}
                              {": "}
                              {row.sunetId}
                            </Typography>
                          </React.Fragment>
                        }
                        interactive
                        placement="bottom-start"
                      >
                        <Link
                          to={{
                            pathname:
                              "/faculty/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                        >
                          {row.primaryName}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <Tooltip title={row.title} interactive>
                      <TableCell align="left">{row.title}</TableCell>
                    </Tooltip>
                    <Tooltip title={row.facultyLine} interactive>
                      <TableCell align="left">{row.facultyLine}</TableCell>
                    </Tooltip>
                    <Tooltip title={row.department.name} interactive>
                      <TableCell align="left">{row.department.name}</TableCell>
                    </Tooltip>
                    <TableCell align="left">
                      {row.startDate && FromDateStrToFLADateStr(row.startDate)}
                    </TableCell>
                    <TableCell align="left">
                      {row.endDate && FromDateStrToFLADateStr(row.endDate)}
                    </TableCell>
                    <TableCell align="left">{row.activeFlag}</TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={t("FLA.mainView.list.tooltips.actions.text")}
                      >
                        <Link
                          to={{
                            pathname:
                              "/faculty/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                        >
                          <ListAltIcon fontSize="small" />
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {!loading && !facultyLeaveList.totalCount && (
                <TableRow>
                  <TableCell colSpan="10">
                    <Typography
                      variant="h6"
                      color="inherit"
                      align="center"
                      paragraph={false}
                    >
                      {t("globals.list.messages.noData")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={facultyLeaveList.totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={pagination.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
};
