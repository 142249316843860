import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperWidthSm: {
    maxWidth: "510px",
    overflowY: "unset",
  },
  typographyBody1: {
    fontStyle: "italic",
  },
  dialogContentRoot: {
    overflowY: "unset",
  },
  categoryIconColor: (props) => ({
    color: props.color,
  }),
  datePickerTextFieldWidth: {
    width: "150px",
  },
  inputBase: {
    height: "40px",
    paddingRight: "0px",
  },
  textFieldWidth: {
    width: "190px",
  },
  outlinedInputHeight: {
    height: "22px",
  },
  standardInputColor: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "&.MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
  },
  dialogActionRoot: {
    paddingBottom: "21px",
    paddingRight: "0px",
  },
}));
