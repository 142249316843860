import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pageContentContainer: {
    padding: "24px 24px",
  },
  filterPanelContainer: {
    borderRight: "1px solid",
    borderRightColor: theme.palette.grey[400],
  },
}));
