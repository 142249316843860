import axios from "axios";

export const getFacultyLeaveList = async (
  setFacultyLeaveList,
  setLoading,
  facultyLeaveFiltersPreference,
  page,
  rowsPerPage,
  orderBy,
  order,
  history,
  setAlert,
  clearAlert
) => {
  try {
    setLoading(true);
    clearAlert();
    const sortString =
      orderBy === "name"
        ? `lastName:${order},firstName:${order},middleName:${order}`
        : `${orderBy}:${order}`;

    const queryString = Object.entries(facultyLeaveFiltersPreference)
      .filter(([prop, value]) => Boolean(value))
      .map(([key, value], index) => `${key}=${value}`)
      .join("&");

    history.push(`${history.location.pathname}?${queryString}`);
    const request = {
      url: `/faculty`,
      params: {
        person: facultyLeaveFiltersPreference.universityId,
        department: facultyLeaveFiltersPreference.department,
        division: facultyLeaveFiltersPreference.division,
        line: facultyLeaveFiltersPreference.line,
        status: facultyLeaveFiltersPreference.status,
        apptType: facultyLeaveFiltersPreference.apptType,
        p: parseInt(page) + 1,
        ps: parseInt(rowsPerPage),
        s: sortString,
      },
    };

    const response = await axios(request);
    setFacultyLeaveList(response.data);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};
