import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper} from "@material-ui/core";
import {useStyles} from "./LeaveRemarksDialog.styles";
import Draggable from "react-draggable";
import React from "react";
import {useTranslation} from "react-i18next";

export const LeaveRemarksDialog = (props) => {
  const {t} = useTranslation();
  const styles = useStyles({})
  const {
    setShowDialog,
    showDialog,
    leave,
  } = props;

  function PaperComponent(props) {
    return (
      <Draggable cancel=".selectable">
        <Paper {...props} />
      </Draggable>
    );
  }

  function renderTitle(leave) {
    return (
      <span className="selectable">
        {leave.title} - {leave.dataSource} - {leave.startDate}&ndash;{leave.endDate}{leave.percentageOnDuty ? ` - ${leave.percentageOnDuty}%` : ""}
      </span>
    );
  }

  function renderContent(leave) {
    return (
      <span className="selectable">
        {`${leave.remarks}`}
      </span>
    );
  }

  return (
    <Dialog
      open={showDialog}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      hideBackdrop={true}
      disableScrollLock={true}
      onEscapeKeyDown={(event) => {
        event.stopPropagation();
        setShowDialog(false);
      }}
      classes={{paperScrollPaper: styles.paperScrollPaper}}
    >
      <DialogTitle id="draggable-dialog-title" disableTypography classes={{root: styles.dialogTitleRoot}}>
        {renderTitle(leave)}
      </DialogTitle>
      <DialogContent classes={{root: styles.dialogContentRoot}}>
        <DialogContentText>
          {renderContent(leave)}
        </DialogContentText>
        <DialogActions classes={{root: styles.dialogActionsRoot}}>
          <Grid
            container
            item
            xs={8}
            spacing={2}
            justify="flex-end"
            wrap="nowrap"
          >
            <React.Fragment>
              <Grid item container xs={6}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={() => setShowDialog(false)}
                  color="primary"
                >
                  {t("globals.form.actionButtons.close")}
                </Button>
              </Grid>
            </React.Fragment>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
