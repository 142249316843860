import { makeStyles } from "@material-ui/core/styles";

const SAB_COLOR = "#BE8DDA";
const PDL_COLOR = "#7ADFA9";

export const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "100%",
  },
  dialogRoot: {
    padding: "10px 24px 0px",
  },
  formControl: {
    marginRight: "35px",
  },
  radioGroup: {
    paddingBottom: "0px",
  },
  sabRadio: {
    "&.Mui-checked": {
      color: SAB_COLOR,
    },
  },
  pdlRadio: {
    "&.Mui-checked": {
      color: PDL_COLOR,
    },
  },
  tableContainer: {
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    boxShadow: "unset",
  },
  tableHead: {
    background: theme.palette.primary.light,
  },
  tableHeadCell: {
    fontWeight: 600,
  },
  mainHeadTitle: {
    fontSize: "17px",
    paddingLeft: "12px",
    paddingBottom: "0px",
    borderBottom: "unset",
  },
  identifierCell: {
    paddingLeft: "38px",
  },
  dialogActions: {
    paddingBottom: "16px",
  },
  accrualInput: {
    width: "80%",
  },
  accrualInputPadding: {
    padding: "5.5px 14px",
  },
}));
