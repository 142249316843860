// src/services/usersService.js
// Users service for the "users/me" endpoint, handling currentUser state management and API errors.
// Exports a useUsers custom hook for accessing currentUser data, such as displayName and permissions.
// Applications should not modify this file since this is a common service.

import React from "react";
import axios from "axios";
import { useAlertContext } from "@stanford-tds/as-components";

const UsersContext = React.createContext();

// Context provider
export const UsersProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState({
    permissions: {},
    roles: [],
  });
  const { setAlert } = useAlertContext();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          url: "/users/me",
        });

        setCurrentUser(response.data);
      } catch (error) {
        // Rules for handling API errors in all services:
        // 1. For all errors, an error message must be displayed to the user. The following rules cover what error message
        //    to display and where to display the error message.
        // 2. In the error object, error.message contains a default error message that's already set for the current error.
        //    If there is a story requirement to display a custom error message for the current error, then the service must
        //    replace the default error message with the custom error message.
        // 3. To determine if the current error is one the story requires to be custom, the service should inspect the combination
        //    of error.status and error.code in the error object.
        // 4. If the current error is required to be custom, the service should lookup the custom error message in translation.json
        //    and if needed format it with information from error.response.data in the error object.
        // 5. If the default error message has been replaced by a custom error message, the service must set the custom error
        //    message to display in either the ASAlert component or a form field error, depending on the story requirements.
        // 6. If the default error message has not been replaced, the service must set the default error message to display
        //    in the ASAlert component.

        // IMPORTANT: This default setAlert must be called if no custom error message has been set
        setAlert("error", error.message);
      }
    })();
  }, [setAlert]);

  return (
    <UsersContext.Provider value={{ currentUser }}>
      {children}
    </UsersContext.Provider>
  );
};

// Custom hook
export const useUsers = () => {
  return React.useContext(UsersContext);
};
