import React from "react";
/**  Material UI Imports **/
import {
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  TextField,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import {Help as HelpIcon, Info as InfoIcon, Lens as LensIcon} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
/** Custom Component **/
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
import { FormatDecimalValues } from "../../../Utils/FormatNumericValues";
import { FromDateStrToFLADateStr } from "../../../Utils/FormatDateValues";
/** Custom Styling **/
import { useStyles } from "./AccrualAdjustment.styles";
/** ENUM import **/
import {AdjustmentType, SegmentsColor} from "../../../constants";

import { default as NumberFormat } from "react-number-format";
import Draggable from "react-draggable";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
/** Service Import */
import {
  adjustAccruals,
  getAccrualBegBalance,
} from "../../../services/accrualAdjustmentService";
/** Context and Component to show error on UI **/
import { useAlertContext } from "@stanford-tds/as-components";
import { ASAlert } from "@stanford-tds/as-components";

const PaperComponent = (props) => {
  return (
    <Draggable
      cancel="input,textarea,button"
      bounds={{
        top: -(window.innerHeight / 2 + 200),
        bottom: window.innerHeight / 2 + 200,
        right: window.innerWidth / 2 + 200,
        left: -(window.innerWidth / 2 + 200),
      }}
    >
      <Paper {...props} />
    </Draggable>
  );
};

// Initial Form Values
const accrualAdjustFormValues = {
    effectiveDate: "",
    existingBegBalance: 0.0,
    adjustValue: "",
    adjustType: AdjustmentType.ADD,
    notes: "",
};
// **** Form Validation Schema ****
const AccrualAdjustmentFormSchema = Yup.object().shape({
  effectiveDate: Yup.string().required("Required"),
  adjustValue: Yup.number().required("Required"),
});

export const AccrualAdjustment = (props) => {
  const { t } = useTranslation();
  const accrualAdjustmentLabels = "LeaveAccruedTaken.accrualLabels.accrualAdjustmentDialog";
  const {
    open,
    handleCancel,
    category,
    year,
    noteTooltip,
    setReloadAccrual,
    setReloadAudit,
  } = props;
  //Get universityId from url params
  const { universityId } = useParams();
  const { alert, setAlert, clearAlert } = useAlertContext();
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] = React.useState(false);
  const [accrualBegBalance, setAccrualBegBalance] = React.useState("");


    /** Function to call on form submit */
  const handleFormSubmit = (values) => {
    adjustAccruals(
      setReloadAccrual,
      setReloadAudit,
      handleCancel,
      setAlert,
      setShowSaveConfirmationMsg,
      {
        universityId,
        values,
        category,
      }
    );
  };
  /** Helper function to populate Effective Date dropdown options.
      It returns the first date of 12 months in the selected Accrual Adjustment year. */
  const populateEffectiveDateOptions = (accrualAdjustmentYear) => {
    let firstDate = new Date(accrualAdjustmentYear, 0);
    const dateOptions = Array.apply(null, { length: 12 }).map((_, month) =>
      FromDateStrToFLADateStr(firstDate.setMonth(month))
    );
    return dateOptions;
  };

  const dateOptions = populateEffectiveDateOptions(year);

  const calculateResultingEndBalance = (values) => {
      if (!accrualBegBalance || !values.adjustValue || !values.adjustType) {
          //  we don't have all values yet -> keep field empty
          return "";
      }

      let adjustValue = valueAndTypeToSignedValue(values.adjustValue, values.adjustType);
      return FormatDecimalValues(accrualBegBalance + adjustValue);
  }

  const valueAndTypeToSignedValue = (value, type) => {
    let v = parseFloat(value);
    let result = 0.0;
    if (type === AdjustmentType.ADD) {
      result = v;
    } else if (type === AdjustmentType.SUBTRACT) {
      result = -v;
    }
    return result;
  }

  /** Helper function to populate Beg. Balance depending on
      selected Effective Date and Leave Type */
  const handleEffectiveDateChange = (effectiveDate, category, setFieldValue) => {
    if (effectiveDate) {
      getAccrualBegBalance(setAlert, {
        universityId,
        effectiveDate,
        category,
      }).then(result => {
        setAccrualBegBalance(result.begBalance);
        if (result.accrualAdjusted) {
          setFieldValue("adjustValue", result.adjustValue, false);
          setFieldValue("adjustType", result.adjustType, false);
        } else {
          setFieldValue("adjustValue", "", false)
        }
      });
    } else {
      setAccrualBegBalance("");
      setFieldValue("adjustValue", "", false)
    }
  };

  // To use the makeStyles
  const classes = useStyles({ color: SegmentsColor[`${category}_LIGHT`] });
  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      disableBackdropClick={true}
      aria-labelledby="draggable-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
      }}
      fullWidth={true}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
      disableScrollLock={true}
      onEscapeKeyDown={(event) => {
        event.stopPropagation();
        handleCancel();
      }}
      onEnter={() => {
        clearAlert();
        setAccrualBegBalance("");
      }}
    >
      <DialogTitle id="draggable-dialog-title" disableTypography={true}>
        <Grid container justify="space-between" direction="row" wrap="nowrap">
          <Grid container item spacing={1}>
            <Grid item>
              <LensIcon
                fontSize="small"
                classes={{ root: classes.categoryIconColor }}
              />
            </Grid>
            {t(`${accrualAdjustmentLabels}.title`, {
              category: category,
            })}
            <Grid item>
              <Typography
                variant="body1"
                classes={{ body1: classes.typographyBody1 }}
              >
                {t(`${accrualAdjustmentLabels}.impNote`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="body2">
                    {t(`${accrualAdjustmentLabels}.helpIconTooltipInitialText`)}
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    {t(`${accrualAdjustmentLabels}.helpIconTooltipText`)}
                  </Typography>
                </React.Fragment>
              }
              placement="right-start"
            >
              <HelpIcon color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Formik
          initialValues={accrualAdjustFormValues}
          enableReinitialize={true}
          validationSchema={AccrualAdjustmentFormSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) =>
            handleFormSubmit(values)
          }
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleBlur,
              dirty,
              isValid,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form>
                <Grid container>
                  <Grid container item spacing={3} direction="column">
                    {alert.exists && (
                      <Grid item>
                        <ASAlert />
                      </Grid>
                    )}
                    <Grid item>
                      <Autocomplete
                        id="effectiveDate"
                        options={dateOptions || []}
                        clearOnEscape={true}
                        value={values.effectiveDate}
                        onChange={(e, value) => {
                          props.setFieldValue("effectiveDate", value);
                          handleEffectiveDateChange(value, category, setFieldValue);
                        }}
                        closeIcon={false}
                        blurOnSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("globals.labels.effectiveDate")}*`}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid container item justify="space-between">
                      <Grid container item justify="space-between" xs={6}>
                        <Grid item>
                          <TextField
                            id="existingBegBalance"
                            label={t(`${accrualAdjustmentLabels}.existingBegBalance`)}
                            size="small"
                            value={FormatDecimalValues(accrualBegBalance)}
                            variant="standard"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                root: classes.standardInputColor,
                              },
                            }}
                            classes={{root: classes.textFieldWidth}}
                            disabled
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="resultingBegBalance"
                            label={t(`${accrualAdjustmentLabels}.resultingBegBalance`)}
                            size="small"
                            value={calculateResultingEndBalance(values)}
                            variant="standard"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                root: classes.standardInputColor,
                              },
                            }}
                            classes={{root: classes.textFieldWidth}}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container item justify="space-between" xs={6} alignContent="center" alignItems="center">
                        <Grid container item xs={2}>
                          <Grid container item>
                            <RadioGroup aria-label="adjustmentType" name="adjustType" value={values.adjustType} onChange={handleChange}>
                              <FormControlLabel value={AdjustmentType.ADD} control={<Radio color="primary"/>} label={t(`${accrualAdjustmentLabels}.adjustTypeAdd`)}/>
                              <FormControlLabel value={AdjustmentType.SUBTRACT} control={<Radio color="primary"/>} label={t(`${accrualAdjustmentLabels}.adjustTypeSubtract`)}/>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <NumberFormat
                            id="adjustValue"
                            label={`${t(`${accrualAdjustmentLabels}.adjustValue`)}*`}
                            value={values.adjustValue}
                            customInput={TextField}
                            variant="outlined"
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isAllowed={(values) => {
                              const {value} = values;
                              return value >= 0.000 && value <= 999.0;
                            }}
                            decimalScale={3}
                            allowNegative={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={noteTooltip ? 11 : 12}>
                        <TextField
                          id="notes"
                          label={t(
                            `${accrualAdjustmentLabels}.accrualAdjustmentNotes`
                          )}
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          autoComplete="off"
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item>
                        {noteTooltip &&
                        <Box m={1}>
                          <Tooltip title={noteTooltip}>
                            <InfoIcon color="primary" fontSize="small" viewBox="0 0 22 22"/>
                          </Tooltip>
                        </Box>
                        }
                      </Grid>
                    </Grid>
                    <Grid item>
                      <DialogActions
                        classes={{ root: classes.dialogActionRoot }}
                      >
                        <Grid
                          item
                          container
                          xs={6}
                          spacing={2}
                          justify="flex-end"
                          wrap="nowrap"
                        >
                          <Grid container item xs={6}>
                            <Button
                              onClick={handleCancel}
                              variant="outlined"
                              fullWidth={true}
                              color="primary"
                            >
                              {t("globals.form.actionButtons.cancel")}
                            </Button>
                          </Grid>
                          <Grid container item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!dirty || !isValid}
                              fullWidth={true}
                              onClick={() => setShowSaveConfirmationMsg(true)}
                            >
                              {t("globals.form.actionButtons.save")}
                            </Button>
                            <ASConfirmation
                              open={showSaveConfirmationMsg}
                              message={t(
                                `${accrualAdjustmentLabels}.saveConfirmationMsg`
                              )}
                              handleCancel={() => {
                                setShowSaveConfirmationMsg(false);
                              }}
                              handleOk={handleSubmit}
                              okLabel={t("globals.form.actionButtons.yes")}
                              cancelLabel={t("globals.form.actionButtons.no")}
                            />
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
