import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useStyles } from "./LeaveAccruedTakenAccordion.styles";
import { useTranslation } from "react-i18next";

export const LeaveAccruedTakenAccordion = (props) => {
  const { isSubExpansionPanel, hasSubSectionsExpansion } = props.children;

  const [expanded, setExpanded] = React.useState(
    props.children.defaultExpanded
  );

  const handleChange = () => (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  React.useEffect(() => {
    if (props.children.expanded !== undefined) {
      setExpanded(props.children.expanded);
    }
  }, [setExpanded, props.children.expanded]);

  // To use the makeStyles
  const classes = useStyles({ isSubExpansionPanel, hasSubSectionsExpansion });
  const { t } = useTranslation();
  return (
    <Accordion
      square={false}
      elevation={props.children.isSubExpansionPanel ? 0 : 1}
      classes={{
        root: classes.accordionRoot,
      }}
      key={props.children.title}
      expanded={expanded}
      onChange={handleChange()}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expandIcon: classes.expandIcon,
          root: classes.accordionSummaryRoot,
          content: classes.accordionContent,
        }}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{ edge: "start", disableRipple: true, size: "medium" }}
      >
        <Typography variant={(isSubExpansionPanel && "body1") || "h2"}>
          {props.children.title}
        </Typography>
        {props.children.contentButton ?? ""}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        {props.children.isSubOrdinateBtn && (
          <Grid
            container
            justify={"flex-start"}
            className={classes.subOrdinateBtn}
          >
            <Grid item xs>
              <Button
                variant="outlined"
                color="primary"
                disabled={!props.children.isButtonEnable}
                onClick={props.children.handleChangeSubOrdinateBtn}
              >
                {props.children.subOrdinateBtnText}
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Tooltip
                title={
                  props.children.leaveNotes ? props.children.leaveNotes : ""
                }
                placement="bottom-start"
                interactive
              >
                <Typography
                  noWrap
                  component="span"
                  className={classes.leaveNotes}
                >
                  <i>
                    {props.children.leaveNotes ? props.children.leaveNotes : ""}
                  </i>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        {React.Children.map(props.children.summaryDetails, (child) => {
          return React.cloneElement(child);
        })}
      </AccordionDetails>
    </Accordion>
  );
};
