import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const FacultyLeaveContext = React.createContext();

export const FacultyLeaveProvider = ({ children }) => {
  const location = useLocation();

  const [facultyLeaveFiltersPreference, setFacultyLeaveFiltersPreference] =
    React.useState(initialFacultyLeaveFiltersState(location));

  const [pagination, setPagination] = React.useState({
    page: 0,
  });

  return (
    <FacultyLeaveContext.Provider
      value={{
        facultyLeaveFiltersPreference,
        setFacultyLeaveFiltersPreference,
        pagination,
        setPagination,
      }}
    >
      {children}
    </FacultyLeaveContext.Provider>
  );
};

export const initialFacultyLeaveFiltersState = (location) => {
  const queryStr = queryString.parse(location.search);

  return {
    universityId: queryStr.universityId || "",
    department: queryStr.department || "",
    division: queryStr.division || "",
    line: queryStr.line || "",
    status: queryStr.status || "Active",
    apptType: queryStr.apptType || "SAB,PDL",
  };
};

export const useFacultyLeaveContext = () => {
  return React.useContext(FacultyLeaveContext);
};
