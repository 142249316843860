import React from "react";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { AccrualAdjustment } from "./AccrualAdjustment/AccrualAdjustment";
import { FormatNegativeValues } from "../../Utils/FormatNumericValues";
/** Material UI Imports **/
import { Typography, Grid } from "@material-ui/core";
import {
  Info as InfoIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
/** Custom Styles **/
import { useStyles } from "./LeaveAccruedTaken.styles";

import { useTranslation } from "react-i18next";

//Function to create the subsection panels of Accrual
export const AccrualSubPanels = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const accrualLabels = "LeaveAccruedTaken.accrualLabels";
  const {
    sabbaticalLeaves,
    pdlLeaves,
    additionalLeaveDetails,
    sabFileName,
    pdlFileName,
    year,
  } = props.leavesData;
  const leavesTypeArray = [sabbaticalLeaves, pdlLeaves];

  const classes = useStyles();

  const createTooltipValue = () => {
    return <React.Fragment>
      <Grid container>
        {additionalLeaveDetails &&
        t(`${accrualLabels}.tooltipTexts.tooltipDataText`, {
          filename:
            (pdlLeaves && pdlFileName) ||
            (sabbaticalLeaves && sabFileName),
        })}
      </Grid>
      <br/>
      {additionalLeaveDetails &&
      additionalLeaveDetails.map((data, key) => (
        <Typography variant="caption" key={key}>
          {data.comment &&
          data.comment.split("\n").map((value) => (
            <React.Fragment key={value}>
              {value}
              <br/>
            </React.Fragment>
          ))}
          {data.note &&
          data.note.split("\n").map((value) => (
            <React.Fragment key={value}>
              {value}
              <br/>
            </React.Fragment>
          ))}
          <br/>
        </Typography>
      ))}
    </React.Fragment>;
  };

  let columnData = [
    {
      id: "jan",
      value: t(`${accrualLabels}.jan`),
      width: "7%",
    },
    {
      id: "feb",
      value: t(`${accrualLabels}.feb`),
      width: "7%",
    },
    {
      id: "mar",
      value: t(`${accrualLabels}.mar`),
      width: "7%",
    },
    {
      id: "apr",
      value: t(`${accrualLabels}.apr`),
      width: "7%",
    },
    {
      id: "may",
      value: t(`${accrualLabels}.may`),
      width: "7%",
    },
    {
      id: "jun",
      value: t(`${accrualLabels}.jun`),
      width: "7%",
    },
    {
      id: "jul",
      value: t(`${accrualLabels}.jul`),
      width: "7%",
    },
    {
      id: "aug",
      value: t(`${accrualLabels}.aug`),
      width: "7%",
    },
    {
      id: "sep",
      value: t(`${accrualLabels}.sep`),
      width: "7%",
    },
    {
      id: "oct",
      value: t(`${accrualLabels}.oct`),
      width: "7%",
    },
    {
      id: "nov",
      value: t(`${accrualLabels}.nov`),
      width: "7%",
    },
    {
      id: "dec",
      value: t(`${accrualLabels}.dec`),
      tooltipHeader:
        additionalLeaveDetails &&
        t(`${accrualLabels}.tooltip.accrualRate.text`),
      tooltipIcon: additionalLeaveDetails && (
        <InfoIcon color="primary" fontSize="small" viewBox="0 0 22 22" />
      ),
      tooltipValue: createTooltipValue(),
    },
  ];
  const rowsData = [];
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const columnsData = [];
  // State for Dialog
  const [
    openAccrualAdjustmentDialog,
    setOpenAccrualAdjustmentDialog,
  ] = React.useState(false);
  const [category, setCategory] = React.useState();
  leavesTypeArray.length &&
    leavesTypeArray
      .filter((prop, value) => Boolean(prop))
      .map((data, key, index) => {
        // The first column header needs to be dynamic as per leave type
        let columnHeader = [];
        columnHeader = [
          {
            id: "row_title",
            value:
              sabbaticalLeaves && pdlLeaves
                ? key > 0
                  ? t(`${accrualLabels}.pdl`)
                  : t(`${accrualLabels}.sabbatical`)
                : pdlLeaves
                ? t(`${accrualLabels}.pdl`)
                : t(`${accrualLabels}.sabbatical`),
            width: "14%",
            icon: props.editAccrual && (
              <React.Fragment>
                <EditOutlinedIcon
                  onClick={() => {
                    setOpenAccrualAdjustmentDialog(true);
                    setCategory(
                      sabbaticalLeaves && pdlLeaves
                        ? key > 0
                          ? t(`${accrualLabels}.pdl`)
                          : t("globals.labels.categorySAB")
                        : pdlLeaves
                        ? t(`${accrualLabels}.pdl`)
                        : t("globals.labels.categorySAB")
                    );
                  }}
                  fontSize="small"
                  viewBox="0 0 25 25"
                  classes={{ root: classes.editDeleteSvgIconRoot }}
                />
              </React.Fragment>
            ),
          },
          ...columnData,
        ];
        columnsData.push(columnHeader);

        let leaveData = {
          beginningBalance: {},
          accrued: {},
          taken: {},
          endBalance: {},
        };
        data.map((data, key) => {
          leaveData.accrued[months[key]] = {
            value:
              data.accrued &&
              FormatNegativeValues(data.accrued, classes.negativeNumberColor),
          };
          leaveData.beginningBalance[months[key]] = {
            value:
              data.beginningBalance &&
              FormatNegativeValues(
                data.beginningBalance,
                classes.negativeNumberColor
              ),
          };
          leaveData.endBalance[months[key]] = {
            value:
              data.endBalance &&
              FormatNegativeValues(
                data.endBalance,
                classes.negativeNumberColor
              ),
          };
          leaveData.taken[months[key]] = {
            value:
              (data.taken &&
                FormatNegativeValues(
                  data.taken,
                  classes.negativeNumberColor
                )) ||
              0,
          };
          return leaveData;
        });

        const leavesData =
          leaveData &&
          Object.entries(leaveData).map(([key, value]) => {
            return {
              ...value,
              row_title: { value: t(`${accrualLabels}.${key}`) },
            };
          });

        rowsData.push(leavesData);
        return rowsData;
      });
  return (
    <Grid container spacing={2} direction="column">
      {rowsData.length > 0 &&
        rowsData.map((data, key) => (
          <Grid item container key={key} direction="row">
            <Grid
              item
              classes={{
                item:
                  (columnsData[key][key].value ===
                    t(`${accrualLabels}.sabbatical`) &&
                    classes.sabColorIndicatorBar) ||
                  classes.pdlColorIndicatorBar,
              }}
            ></Grid>
            <Grid item xs={11}>
              <AccordionSummaryTable
                columnData={columnsData[key]}
                rowData={data}
              />
            </Grid>
          </Grid>
        ))}
      <AccrualAdjustment
        open={openAccrualAdjustmentDialog}
        handleCancel={() => {
          setOpenAccrualAdjustmentDialog(false);
        }}
        category={category}
        year={year}
        noteTooltip={additionalLeaveDetails && createTooltipValue()}
        setReloadAccrual={props.setReloadAccrual}
        setReloadAudit={props.setReloadAudit}
      />
    </Grid>
  );
};
