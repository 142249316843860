import React from "react";
import { useStyles } from "./AccordionSummaryTable.styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Grid,
} from "@material-ui/core";

export const AccordionSummaryTable = (props) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {props.columnData.map((row, index) => (
              <TableCell
                align="left"
                key={index}
                width={row.width}
                classes={{
                  root: classes.tableCellRoot,
                  head: classes.tableHeadCell,
                  sizeSmall: classes.denseTablePadding,
                }}
              >
                {row.tooltipHeader || row.icon ? (
                  <Grid container spacing={1} direction="row">
                    {row.icon ? (
                      <React.Fragment>
                        <Grid item xs={8}>
                          {row.value}
                        </Grid>
                        <Grid item xs={2}>
                          {row.icon}
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item>{row.value}</Grid>
                        <Grid item xs={(row.tooltipValue && 4) || 2}>
                          <Tooltip
                            title={row.tooltipValue ?? row.tooltipHeader}
                            placement="bottom-start"
                            interactive
                          >
                            {row.tooltipIcon ?? ""}
                          </Tooltip>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ) : (
                  row.value
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rowData.map((row, index) => (
            <TableRow
              key={index}
              classes={{
                root:
                  row["rowColor"] && row["rowColor"].value && classes.rowColor,
              }}
            >
              {props.columnData.map((column, key) => (
                <TableCell
                  align="left"
                  width={column.width}
                  key={key}
                  classes={{
                    root: classes.tableCellRoot,
                    sizeSmall: classes.denseTablePadding,
                  }}
                >
                  <Grid container alignItems="flex-end" spacing={2}>
                    {row[column.id] &&
                      row[column.id].segmentIcon &&
                      !row[column.id].rowReverse && (
                        <Grid item>{row[column.id].segmentIcon}</Grid>
                      )}
                    <Grid item classes={{ item: classes.tableCellEllipsis }} xs>
                      <Tooltip
                        title={column.tooltip ? row[column.id].value : ""}
                        placement="bottom-start"
                        interactive
                      >
                        <Typography
                          noWrap
                          classes={{
                            body1:
                              (row[column.id] &&
                                row[column.id].fontStyle &&
                                classes.body1) ||
                              (row[column.id] &&
                                classes[row[column.id].fontColor]),
                          }}
                          // use "span" as root node (instead of 'p') to avoid warning "<div> cannot appear as a descendant of <p>"
                          // see: https://stackoverflow.com/questions/41928567/div-cannot-appear-as-a-descendant-of-p
                          component="span"
                        >
                          {row[column.id] && row[column.id].value}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    {row[column.id] &&
                      row[column.id].segmentIcon &&
                      row[column.id].rowReverse && (
                        <Grid item>{row[column.id].segmentIcon}</Grid>
                      )}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
