import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "100%",
  },
  dialogTitleRoot: {
    padding: "16px 24px 0px",
  },
  dialogContentRoot: {
    overflowY: "hidden",
  },
  standardInputColor: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "&.MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
  },
  tableContainer: {
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    boxShadow: "unset",
  },
  tableHead: {
    background: theme.palette.primary.light,
  },
  tableHeadCell: {
    fontWeight: 600,
    padding: "15px",
  },
  tableCell: {
    padding: "15px",
  },
  dialogActions: {
    paddingRight: "25px",
    paddingBottom: "16px",
  },
}));
