import React from "react";
/**  Material UI Imports **/
import {
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Lens as LensIcon, Help as HelpIcon } from "@material-ui/icons";
/** Custom Component **/
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
/** Custom Styling **/
import { useStyles } from "./ViewEditIndividualMilestone.styles";
/** ENUM import **/
import { SegmentsColor } from "../../../constants";

import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
/** Service Import */
import {
  getIndividualMilestoneDetailsById,
  updateIndividualMilestone,
} from "../../../services/individualMilestoneService";
/** Context and component to show error */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

const PaperComponent = (props) => {
  return (
    <Draggable
      cancel="input,textarea,button"
      bounds={{
        top: -(window.innerHeight / 2 + 200),
        bottom: window.innerHeight / 2 + 200,
        right: window.innerWidth / 2 + 200,
        left: -(window.innerWidth / 2 + 200),
      }}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export const ViewEditIndividualMilestone = (props) => {
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlertContext();
  const editViewIndividualMilestoneLabels =
    "LeaveAccruedTaken.individualMilestoneForm";
  const {
    open,
    handleCancel,
    universityId,
    milestoneId,
    actionType,
    setReloadAudit,
  } = props;
  const [individualMilestoneForm, setIndividualMilestoneForm] = React.useState({
    category: "",
    date: "",
    description: "",
    dailyAccrual: "",
    maxAccrual: "",
    note: "",
  });
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] =
    React.useState(false);
  /* Function to format the Decimal Values,
   * if the length of Decimal digits is greater than 3,
   * then round-off the value to 3 decimal places (keeping the trailing zeros),
   * otherwise return the same value
   */
  const formatDecimalValues = (value) =>
    value && value % 1 !== 0 && value.toString().split(".")[1].length > 3
      ? value.toFixed(3)
      : value;
  React.useEffect(() => {
    if (open) {
      setIndividualMilestoneForm({});
      getIndividualMilestoneDetailsById(setIndividualMilestoneForm, setAlert, {
        universityId,
        milestoneId,
      });
    }
    if (!open) {
      clearAlert();
    }
  }, [open, universityId, milestoneId, setAlert, clearAlert]);
  // To use the makeStyles
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      disableBackdropClick={true}
      aria-labelledby="draggable-dialog-title"
      onClick={(event) => {
        event.stopPropagation();
      }}
      classes={{ paperScrollPaper: classes.paperScrollPaper }}
      fullWidth={true}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
      disableScrollLock={true}
      onEscapeKeyDown={(event) => {
        event.stopPropagation();
        handleCancel();
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={individualMilestoneForm}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          updateIndividualMilestone(
            handleCancel,
            setShowSaveConfirmationMsg,
            setReloadAudit,
            setAlert,
            {
              universityId,
              milestoneId,
              values,
            }
          );
        }}
      >
        {(props) => {
          const { values, dirty, isValid, handleChange, handleSubmit } = props;
          return (
            <form>
              <DialogTitle
                id="draggable-dialog-title"
                disableTypography={true}
                classes={{ root: classes.dialogTitleRoot }}
              >
                {(actionType === "edit" &&
                  t(
                    `${editViewIndividualMilestoneLabels}.editIndividualMilestoneLabel`
                  )) ||
                  t(
                    `${editViewIndividualMilestoneLabels}.viewIndividualMilestoneLabel`
                  )}
              </DialogTitle>
              <DialogContent classes={{ root: classes.dialogContentRoot }}>
                {alert.exists && (
                  <Grid container>
                    <ASAlert />
                  </Grid>
                )}
                <Grid container item spacing={1} alignItems="flex-end">
                  <Grid item>
                    <LensIcon
                      fontSize="small"
                      htmlColor={SegmentsColor[`${values.category}_LIGHT`]}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{values.category}</Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-uncontrolled"
                      label={t(
                        `${editViewIndividualMilestoneLabels}.milestoneDate`
                      )}
                      size="small"
                      value={values.date}
                      variant="standard"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.standardInputColor,
                        },
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <TableContainer
                      component={Paper}
                      classes={{ root: classes.tableContainer }}
                    >
                      <Table aria-label="customized table">
                        <TableHead classes={{ root: classes.tableHead }}>
                          <TableRow>
                            <TableCell
                              align="left"
                              width="32%"
                              classes={{ root: classes.tableHeadCell }}
                            >
                              {t(
                                `${editViewIndividualMilestoneLabels}.identifier`
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              width="20%"
                              classes={{ root: classes.tableHeadCell }}
                            >
                              {t(
                                `${editViewIndividualMilestoneLabels}.dailyAccrual`
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              width="10%"
                              classes={{ root: classes.tableHeadCell }}
                            >
                              {t(
                                "LeaveAccruedTaken.appointmentSegmentsLabels.maxAccrual"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              classes={{ root: classes.tableCell }}
                            >
                              <Grid container spacing={1} wrap="nowrap">
                                <Grid component="span" item>
                                  {values.description}
                                </Grid>
                                <Grid component="span" item>
                                  {values.identifier === "RETURN_TO_NORMAL" && (
                                    <Tooltip
                                      title={t(
                                        `${editViewIndividualMilestoneLabels}.returnToNormalTooltipText`
                                      )}
                                      placement="right-start"
                                    >
                                      <HelpIcon color="primary" />
                                    </Tooltip>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell
                              align="left"
                              classes={{ root: classes.tableCell }}
                            >
                              {formatDecimalValues(values.dailyAccrual)}
                            </TableCell>
                            <TableCell
                              align="left"
                              classes={{ root: classes.tableCell }}
                            >
                              {formatDecimalValues(values.maxAccrual)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    {(actionType === "edit" && (
                      <TextField
                        id="note"
                        label={t(
                          `${editViewIndividualMilestoneLabels}.individualMilestoneNotes`
                        )}
                        value={values.note || ""}
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        multiline
                      />
                    )) || (
                      <TextField
                        label={t(
                          `${editViewIndividualMilestoneLabels}.individualMilestoneNotes`
                        )}
                        size="small"
                        value={values.note}
                        variant="standard"
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            root: classes.standardInputColor,
                          },
                        }}
                        disabled
                        fullWidth
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions classes={{ root: classes.dialogActions }}>
                <Grid
                  container
                  item
                  xs={6}
                  spacing={2}
                  justify="flex-end"
                  wrap="nowrap"
                >
                  <Grid container item xs={5}>
                    <Button
                      onClick={handleCancel}
                      variant={
                        (actionType === "edit" && "outlined") || "contained"
                      }
                      color="primary"
                      fullWidth={true}
                    >
                      {(actionType === "edit" &&
                        t("globals.form.actionButtons.cancel")) ||
                        t("globals.form.actionButtons.close")}
                    </Button>
                  </Grid>
                  {actionType === "edit" && (
                    <Grid item container xs={5}>
                      <Button
                        variant="contained"
                        disabled={!dirty || !isValid}
                        onClick={() => setShowSaveConfirmationMsg(true)}
                        color="primary"
                        fullWidth={true}
                      >
                        {t("globals.form.actionButtons.save")}
                      </Button>
                      <ASConfirmation
                        open={showSaveConfirmationMsg}
                        message={t(
                          `${editViewIndividualMilestoneLabels}.editSaveConfirmationMsg`
                        )}
                        handleCancel={() => setShowSaveConfirmationMsg(false)}
                        handleOk={handleSubmit}
                        okLabel={t("globals.form.actionButtons.yes")}
                        cancelLabel={t("globals.form.actionButtons.no")}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
