import React from "react";
import Draggable from "react-draggable";
/**  Material UI Imports **/
import {
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
/** Custom Component **/
import { IndividualMilestoneAddEditTable } from "./IndividualMilestoneAddEditTable";
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
/** Custom Styling **/
import { useStyles } from "./CreateIndividualMilestone.styles";
/** Service Import */
import { saveIndividualMilestone } from "../../../services/individualMilestoneService";

import DateFnsUtils from "@date-io/date-fns";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

function PaperComponent(props) {
  return (
    <Draggable
      cancel="input,textarea,button"
      bounds={{
        top: -(window.innerHeight / 2 + 200),
        bottom: window.innerHeight / 2 + 200,
        right: window.innerWidth / 2 + 200,
        left: -(window.innerWidth / 2 + 200),
      }}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// **** Form Validation Schema ****
const CreateIndividualMilestoneSchema = Yup.object().shape({
  date: Yup.date().required("Required"),
  identifierSelected: Yup.boolean().test(
    "is-checked",
    "Required",
    (value) => value === true
  ),
  dailyAccrual: Yup.number().nullable(),
  maxAccrual: Yup.number().nullable(),
});

export const CreateIndividualMilestone = (props) => {
  const { t } = useTranslation();
  // To show the error
  const { alert, setAlert, clearAlert } = useAlertContext();
  const individualMilestoneLabel = "LeaveAccruedTaken.individualMilestoneForm";
  const globalLabels = "globals.labels";
  const globalFormLabels = "globals.form";
  // State for Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // Events to handle the open and close state of Dialog
  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  const handleClose = (event) => {
    setOpenDialog(false);
  };

  // Initial Form Values
  const createMilestoneForm = {
    category: t(`${globalLabels}.categorySAB`),
    identifierSelected: false,
    identifier: null,
    description: null,
    dailyAccrual: null,
    maxAccrual: null,
    date: null,
    note: null,
  };
  /** State variables for confirmation messages */
  const [showCancelConfirmationMsg, setShowCancelConfirmationMsg] =
    React.useState(false);
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] =
    React.useState(false);
  /** Call to service on form submit **/
  const handleFormSubmit = (values) => {
    const {
      universityId,
      setReloadAppointmentSegments,
      setReloadAudit,
      setReloadAccrual,
    } = props;
    saveIndividualMilestone(
      setShowSaveConfirmationMsg,
      setOpenDialog,
      setReloadAppointmentSegments,
      setReloadAudit,
      setReloadAccrual,
      setAlert,
      {
        values,
        universityId,
      }
    );
  };
  /** Radio group change handler **/
  const handleRadioChange = (resetForm) => {
    resetForm();
    clearAlert();
  };
  // To clear the error from the Dialog
  React.useEffect(() => {
    if (!openDialog) {
      clearAlert();
    }
  }, [openDialog, clearAlert]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddCircleIcon />
        &nbsp;&nbsp;{" "}
        {t(`${individualMilestoneLabel}.addIndividualMilestoneLabel`)}
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        disableBackdropClick={true}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop={true}
        disableScrollLock={true}
        onClick={(event) => event.stopPropagation()}
        fullWidth={true}
        classes={{ paperScrollPaper: classes.paperScrollPaper }}
      >
        <DialogTitle
          id="draggable-dialog-title"
          disableTypography={true}
          classes={{ root: classes.dialogRoot }}
        >
          {t(`${individualMilestoneLabel}.addIndividualMilestoneLabel`)}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            enableReinitialize={true}
            initialValues={createMilestoneForm}
            validationSchema={CreateIndividualMilestoneSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              handleFormSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                dirty,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
              } = props;
              return (
                <form>
                  <Grid container direction="column" spacing={2}>
                    {alert.exists && <ASAlert />}
                    <Grid item classes={{ item: classes.radioGroup }}>
                      <RadioGroup
                        aria-label="radioGroup"
                        name="category"
                        value={values.category}
                        onChange={(event) => {
                          handleRadioChange(props.resetForm);
                          handleChange(event);
                        }}
                        row={true}
                      >
                        <FormControlLabel
                          value="SAB"
                          control={
                            <Radio
                              classes={{ colorSecondary: classes.sabRadio }}
                            />
                          }
                          label={t(`${globalLabels}.categorySAB`)}
                          classes={{ root: classes.formControl }}
                        />
                        <FormControlLabel
                          value="PDL"
                          control={
                            <Radio classes={{ checked: classes.pdlRadio }} />
                          }
                          label={t(`${globalLabels}.categoryPDL`)}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          label={`${t(
                            `${individualMilestoneLabel}.milestoneDate`
                          )}*`}
                          value={values.date}
                          inputValue={values.date}
                          name="date"
                          inputProps={{
                            autoComplete: "off",
                            maxLength: 250,
                          }}
                          clearable
                          format="yyyy-MM-dd"
                          id="date-picker-inline-milestone"
                          KeyboardButtonProps={{
                            "aria-label": "milestone date",
                          }}
                          onChange={(e, date) => {
                            props.setFieldValue("date", date);
                          }}
                          onError={(e, value) => {
                            e && props.setErrors({ date: e });
                          }}
                          onBlur={handleBlur}
                          error={values.date && errors.date && true}
                          helperText={
                            values.date &&
                            errors.date &&
                            t(
                              `${globalFormLabels}.dateValidationMessages.invalidDateFormat`
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                      <IndividualMilestoneAddEditTable
                        category={values.category}
                        setFieldValue={props.setFieldValue}
                        handleChange={handleChange}
                        setAlert={setAlert}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="note"
                        label={t(
                          `${individualMilestoneLabel}.individualMilestoneNotes`
                        )}
                        value={values.note || ""}
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <DialogActions classes={{ root: classes.dialogActions }}>
                      <Grid
                        container
                        item
                        xs={6}
                        spacing={2}
                        justify="flex-end"
                        wrap="nowrap"
                      >
                        <Grid container item xs={5}>
                          <Button
                            onClick={() => {
                              dirty
                                ? setShowCancelConfirmationMsg(true)
                                : setOpenDialog(false);
                            }}
                            variant="outlined"
                            color="primary"
                            fullWidth={true}
                          >
                            {t(`${globalFormLabels}.actionButtons.cancel`)}
                          </Button>
                          <ASConfirmation
                            open={showCancelConfirmationMsg}
                            message={t(
                              `${individualMilestoneLabel}.cancelConfirmationMsg`
                            )}
                            handleCancel={() =>
                              setShowCancelConfirmationMsg(false)
                            }
                            handleOk={() => {
                              setShowCancelConfirmationMsg(false);
                              setOpenDialog(false);
                            }}
                            okLabel={t(`${globalFormLabels}.actionButtons.yes`)}
                            cancelLabel={t(
                              `${globalFormLabels}.actionButtons.no`
                            )}
                          />
                        </Grid>
                        <Grid item container xs={5}>
                          <Button
                            variant="contained"
                            disabled={
                              !values.dailyAccrual ||
                              !values.maxAccrual ||
                              !dirty ||
                              !isValid
                            }
                            onClick={() => setShowSaveConfirmationMsg(true)}
                            color="primary"
                            fullWidth={true}
                          >
                            {t(`${globalFormLabels}.actionButtons.save`)}
                          </Button>
                          <ASConfirmation
                            open={showSaveConfirmationMsg}
                            message={t(
                              `${individualMilestoneLabel}.saveConfirmationMsg`
                            )}
                            handleCancel={() =>
                              setShowSaveConfirmationMsg(false)
                            }
                            handleOk={handleSubmit}
                            okLabel={t(`${globalFormLabels}.actionButtons.yes`)}
                            cancelLabel={t(
                              `${globalFormLabels}.actionButtons.no`
                            )}
                          />
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
