import React from "react";
/**  Material UI Imports **/
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  OutlinedInput,
  Tooltip,
  Grid,
} from "@material-ui/core";
import { Help as HelpIcon } from "@material-ui/icons";
/** Custom Styling **/
import { useStyles } from "./CreateIndividualMilestone.styles";
/** Service Import */
import { getMilestonesByCategory } from "../../../services/individualMilestoneService";

import { useTranslation } from "react-i18next";

export const IndividualMilestoneAddEditTable = ({
  category,
  setFieldValue,
  handleChange,
  setAlert,
}) => {
  const { t } = useTranslation();
  const individualMilestoneLabel = "LeaveAccruedTaken.individualMilestoneForm";

  const [individualMilestones, setIndividualMilestones] = React.useState([]);
  const [checkedMilestones, setCheckedMilestones] = React.useState([]);

  /** Call to service to get all individual milestones as per category */
  React.useEffect(() => {
    getMilestonesByCategory(setIndividualMilestones, setAlert, category);
    setCheckedMilestones([]);
  }, [category, setAlert]);

  /* Function to format the Decimal Values,
   * if the length of Decimal digits is greater than 3,
   * then round-off the value to 3 decimal places (keeping the trailing zeros),
   * otherwise return the same value
   */
  const formatDecimalValues = (value) =>
    value && value % 1 !== 0 && value.toString().split(".")[1].length > 3
      ? value.toFixed(3)
      : value;
  const [otherIdentifierSelected, setOtherIdentifierSelected] = React.useState(
    false
  );

  const getAccrualCells = (row, otherIdentifierSelected) => {
    if (row.identifier === "OTHER") {
      return (
        <React.Fragment>
          <TableCell align="left">
            {otherIdentifierSelected && (
              <OutlinedInput
                margin="dense"
                classes={{
                  root: classes.accrualInput,
                  inputMarginDense: classes.accrualInputPadding,
                }}
                onChange={(event) => {
                  handleChange(event);
                }}
                autoComplete="off"
                inputProps={{ maxLength: 16 }}
                name="dailyAccrual"
              />
            )}
          </TableCell>
          <TableCell align="left">
            {otherIdentifierSelected && (
              <OutlinedInput
                margin="dense"
                classes={{
                  root: classes.accrualInput,
                  inputMarginDense: classes.accrualInputPadding,
                }}
                autoComplete="off"
                inputProps={{ maxLength: 16 }}
                onChange={(event) =>
                  setFieldValue("maxAccrual", event.target.value)
                }
              />
            )}
          </TableCell>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <TableCell align="left" name="dailyAccrual">
            {formatDecimalValues(row.dailyAccrual)}
          </TableCell>
          <TableCell align="left" name="maxAccrual">
            {formatDecimalValues(row.maxAccrual)}
          </TableCell>
        </React.Fragment>
      );
    }
  };

  const handleCheckboxChange = (event, row) => {
    const eventValue = event.target.value;
    const checked = event.target.checked;
    setCheckedMilestones(
      (!checkedMilestones.includes(eventValue) && [eventValue]) || []
    );
    setOtherIdentifierSelected(
      (row.identifier === "OTHER" && checked && true) || false
    );
    setFieldValue("identifier", (checked && row.identifier) || null);
    setFieldValue("description", (checked && row.description) || null);
    setFieldValue("dailyAccrual", (checked && row.dailyAccrual) || null);
    setFieldValue("maxAccrual", (checked && row.maxAccrual) || null);
    getAccrualCells(row, otherIdentifierSelected);
  };

  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      classes={{ root: classes.tableContainer }}
    >
      <Table aria-label="customized table">
        <TableHead
          classes={{
            root: classes.tableHead,
          }}
        >
          <TableRow>
            <TableCell
              align="left"
              classes={{
                root: classes.tableHeadCell,
                head: classes.mainHeadTitle,
              }}
              colSpan="3"
            >
              {`${t(`${individualMilestoneLabel}.chooseIndividualMilestone`)}*`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              classes={{
                root: classes.tableHeadCell,
                head: classes.identifierCell,
              }}
              width="32%"
            >
              {t(`${individualMilestoneLabel}.identifier`)}
            </TableCell>
            <TableCell
              align="left"
              classes={{ root: classes.tableHeadCell }}
              width="20%"
            >
              {t(`${individualMilestoneLabel}.dailyAccrual`)}
            </TableCell>
            <TableCell
              align="left"
              classes={{ root: classes.tableHeadCell }}
              width="10%"
            >
              {t("LeaveAccruedTaken.appointmentSegmentsLabels.maxAccrual")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {individualMilestones.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left" component="th" scope="row" padding="none">
                <Grid item container alignItems="baseline" component="span">
                  <Grid item component="span">
                    <Checkbox
                      color="primary"
                      checked={checkedMilestones.includes(row.identifier)}
                      disableRipple={true}
                      size="medium"
                      value={row.identifier}
                      onChange={(event) => {
                        handleCheckboxChange(event, row);
                        handleChange(event);
                      }}
                      name="identifierSelected"
                    />
                  </Grid>
                  <Grid container item xs={6} justify="space-between">
                    <Grid component="span" item>
                      {row.description}
                    </Grid>
                    <Grid component="span" item>
                      {/** Show Help Icon & Tooltip when 'Return To Normal' Individual Milestone is selected */}
                      {checkedMilestones.includes(row.identifier) &&
                        row.identifier === "RETURN_TO_NORMAL" && (
                          <Tooltip
                            title={t(
                              `${individualMilestoneLabel}.returnToNormalTooltipText`
                            )}
                            placement="right-start"
                          >
                            <HelpIcon color="primary" />
                          </Tooltip>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
              {getAccrualCells(row, otherIdentifierSelected)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
