import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  facultyLeaveListWrapper: {
    marginRight: "24px",
    marginTop: "6px",
  },
  hide: {
    display: "none",
  },
});
