import React from "react";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { FromUTCDateStrToFLADateTimeStr } from "../../Utils/FormatDateValues";
/** Material UI Imports **/
import { Grid, CircularProgress, Tooltip, IconButton } from "@material-ui/core";

import { useTranslation } from "react-i18next";
/** Service Call **/
import { getAudit } from "../../services/leaveAccrualTakenService";
import { Info as InfoIcon } from "@material-ui/icons";
import { useStyles } from "./LeaveAccruedTaken.styles";

//Function to create the Audit panel
export const Audit = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  //Common string of translation key
  const auditDetailsLabels = "LeaveAccruedTaken.auditLabels";
  const {
    universityId,
    reloadAudit,
    setReloadAudit,
    setPageError,
    setAlert,
    clearAlert,
  } = props;
  const [auditDetails, setAuditDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  /** Call to service to fetch Audit */
  React.useEffect(() => {
    if (reloadAudit || !auditDetails.length) {
      getAudit(
        setAuditDetails,
        setLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
      setReloadAudit(false);
    }
  }, [
    universityId,
    reloadAudit,
    setReloadAudit,
    auditDetails,
    setAlert,
    clearAlert,
    setPageError,
  ]);

  const columnData = [
    {
      id: "sunetId",
      value: t("globals.labels.sunetID"),
      width: "10%",
    },
    {
      id: "userRole",
      value: t(`${auditDetailsLabels}.role`),
      width: "10%",
    },
    {
      id: "changeDate",
      value: t(`${auditDetailsLabels}.date`),
      width: "12%",
    },
    {
      id: "changeType",
      value: t(`${auditDetailsLabels}.action`),
      width: "8%",
    },
    {
      id: "leaveCategory",
      value: t(`${auditDetailsLabels}.category`),
      width: "8%",
    },
    {
      id: "changeObject",
      value: t(`${auditDetailsLabels}.changeObject`),
      width: "11%",
    },
    {
      id: "additionalInfo",
      width: "1%",
    },
    {
      id: "changeDescription",
      value: t(`${auditDetailsLabels}.description`),
      tooltip: true,
      width: "20%",
    },
  ];

  const rowData = [];

  auditDetails.map((data) =>
    rowData.push({
      sunetId: {
        value: data.userName,
      },
      userRole: {
        value: data.userRole,
      },
      changeDate: {
        value: FromUTCDateStrToFLADateTimeStr(data.changeDate),
      },
      changeType: {
        value: data.changeType,
      },
      leaveCategory: {
        value: data.leaveCategory,
        fontColor: `text${data.leaveCategory}Color`,
      },
      changeObject: {
        value: data.changeObject,
      },
      additionalInfo: {
        value: data.additionalInfo && (
          <IconButton aria-label="edit" classes={{ root: classes.iconButtons }}>
            <Tooltip title={data.additionalInfo}>
              <InfoIcon color="primary" />
            </Tooltip>
          </IconButton>
        ),
      },
      changeDescription: {
        value: data.changeDescription,
      },
    })
  );

  return (
    (loading && (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    )) || <AccordionSummaryTable columnData={columnData} rowData={rowData} />
  );
};
