import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import { FilterPanelStyle, DrawerStyle } from "./FilterPanel.styles";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const FilterPanelDef = ({
  open,
  handleDrawerToggle,
  resetMobileView,
  location,
  changeFilterData,
  history,
  children,
}) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // State variable for filter data and its function to set the value
  const [filterData, setFilterData] = React.useState({});

  const width = 760;
  const [mobileView, setMobileView] = React.useState(
    window.outerWidth <= width
  );
  // UseEffect to act on the change of screen size.
  React.useEffect(() => {
    function handleResize() {
      resetMobileView(!(window.outerWidth <= width));
      setMobileView(window.outerWidth <= width);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobileView, resetMobileView]);

  return (
    <FilterPanelStyle open={open}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        className={open ? "hide" : "show"}
      >
        <ChevronRightIcon fontSize="small" />
      </IconButton>
      <DrawerStyle
        variant={mobileView ? "temporary" : "persistent"}
        open={open}
        anchor={mobileView ? "top" : "left"}
        className={!open ? "drawer-root-close" : ""}
        mobile={mobileView ? 1 : 0}
      >
        <div className="filterOptions">
          <div className="PaperToolbar">
            <Typography variant="h6" className="filterTitle">
              {t("FLA.filterPanel.title")}
            </Typography>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
          </div>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              location,
              history,
              changeFilterData,
              filterData,
              setFilterData,
            });
          })}
        </div>
      </DrawerStyle>
    </FilterPanelStyle>
  );
};

export const FilterPanel = withRouter(FilterPanelDef);
