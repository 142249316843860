import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUsers } from "../services/usersService";
import {
  ASHeader,
  ASMain,
  ASNavbar,
  ASAccessDenied,
  ASFooter,
} from "@stanford-tds/as-components";
import { navbar } from "../config";
import { RoutesMapping } from "../Routes/Routes";

// src/components/AppPage.js
// AppPage is the top-level component that can be customized in your single-page application.
const AppPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const navbarConfig = [];
  navbarConfig.push(navbar.items[0]);

  return (
    <>
      <ASHeader
        sitenameTitle={t("app.title")}
        sitenameSubtitle={t("app.subtitle")}
        displayName={currentUser.displayName}
      />
      <ASMain>
        {currentUser.permissions && (
          <>
            <ASNavbar
              menuItems={navbarConfig}
              width={navbar.width}
              history={history}
            />
            <RoutesMapping />
          </>
        )}
        {!currentUser.permissions && (
          <ASAccessDenied supportEmail={t("app.supportEmail")} />
        )}
      </ASMain>
      <ASFooter
        currentYear={t("app.year")}
        buildVersion={process.env.REACT_APP_VERSION}
      />
    </>
  );
};

export default AppPage;
