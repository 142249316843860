import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { Page } from "../components/Page/Page";
import { RoutesList } from "./RoutesList";

const ContextRoute = ({ routes }) => {
  const Provider = routes[0].provider || Fragment;

  return (
    <Provider>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.path}
            render={(props) => (
              <Page
                title={route.title}
                filter={route.filter}
                main={route.main}
              />
            )}
          ></Route>
        ))}
      </Switch>
    </Provider>
  );
};

export const RoutesMapping = () => {
  return Object.keys(RoutesList).map((route, index) => (
    <ContextRoute key={index} routes={RoutesList[route]} />
  ));
};
