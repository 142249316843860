export const navbar = {
  width: 786,
  items: [
    {
      name: "Faculty",
      menuItem: [],
      route: "/faculty",
    },
    {
      name: "Admin",
      menuItem: [
        { name: "Roles", route: "/admin/tabUnderDevelopment" },
        { name: "Accrual Limits", route: "/admin/tabUnderDevelopment" },
        { name: "Department", route: "/admin/tabUnderDevelopment" },
      ],
    },
  ],
};
