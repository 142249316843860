import React from "react";
import { useTranslation } from "react-i18next";
/** Custom Component  */
import { FromDateStrToFLADateTimeStr } from "../../../Utils/FormatDateValues";
/** MUI Imports */
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircle as AddCircleIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
/**Custom Style */
import { useStyles } from "./CreateEditViewLeaveActions.styles";

import Draggable from "react-draggable";
import { Formik } from "formik";
import * as Yup from "yup";
import { default as NumberFormat } from "react-number-format";
import { DefaultDataSource, LeaveActions, LeaveActionsType } from "../../../constants";
/** Service Import */
import {
  saveLeaveAction,
  updateLeaveAction,
  getLeaveDetails,
  getLeaveInfoDetails,
  checkCrossSchoolAppointment,
  getAverageFTELast3Years,
  getLeaveEmailNotification,
  fetchLeaveValidation,
} from "../../../services/leaveActionService";
/**Custom Component */
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
import {
  FormatNegativeValues,
  numberToPercentage,
} from "../../../Utils/FormatNumericValues";
import { isValid as isDateValid, format, addMinutes } from "date-fns";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { EmailNotification } from "./EmailNotification";

function PaperComponent(props) {
  return (
    <Draggable
      cancel="input,textarea,button"
      bounds={{
        top: -(window.innerHeight / 2 + 200),
        bottom: window.innerHeight / 2 + 200,
        right: window.innerWidth / 2 + 200,
        left: -(window.innerWidth / 2 + 200),
      }}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const CreateEditViewLeaveActions = (props) => {
  const { t } = useTranslation();
  // To show the error
  const { alert, setAlert, clearAlert } = useAlertContext();
  const {
    universityId,
    setReloadLeave,
    setReloadAudit,
    leaveId,
    actionType,
    setShowEditLeaveActionDialog,
    showEditLeaveActionDialog,
    setReloadAccrual,
  } = props;
  const leaveActionFormLabel = "LeaveAccruedTaken.leaveActionForm";
  const [actionId, setActionId] = React.useState(leaveId);
  // State for Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  // State for Save confirmation message Dialog
  const [showSaveConfirmationMsg, setShowSaveConfirmationMsg] =
    React.useState(false);
  // State for Cancel confirmation message Dialog
  const [showCancelConfirmationMsg, setShowCancelConfirmationMsg] =
    React.useState(false);

  // State for email notification Dialog
  const [showEmailNotificationDialog, setShowEmailNotificationDialog] =
    React.useState(false);

  const [validationWarning, setValidationWarning] = React.useState("");

  // Events to handle the open and close state of Dialog
  const handleClickOpen = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  //State to indicate show warning icon
  const [hasCrossSchoolAppointment, setHasCrossSchoolAppointment] =
    React.useState();
  // State for Leave Details
  const [leaveDetails, setLeaveDetails] = React.useState({});
  /** Call to service to fetch Leave Details */
  React.useEffect(() => {
    if (showEditLeaveActionDialog) {
      setActionId(leaveId);
      setLeaveDetails({});
      getLeaveDetails(
        setLeaveDetails,
        setHasCrossSchoolAppointment,
        setAlert,
        universityId,
        leaveId
      );
    }
    setDirtyFlag(false);
    if (!openDialog || !showEditLeaveActionDialog) {
      clearAlert();
    }
  }, [
    showEditLeaveActionDialog,
    universityId,
    leaveId,
    openDialog,
    clearAlert,
    setAlert,
  ]);

  const disabledFields = actionType === "view";
  const inputVariantValue = (actionType === "view" && "standard") || "outlined";
  // Initial Form Values
  const leaveActionFormValues = {
    type: leaveDetails.type || t("globals.labels.categorySAB"),
    currentState:
      t(
        LeaveActions[
        leaveDetails.currentState && leaveDetails.currentState.value
        ]
      ) || t(LeaveActions["DRAFT"]),
    actionState: (leaveDetails.currentState && leaveDetails.currentState.value) || "DEPT_OAA_REVIEW",
    nextStates: leaveDetails["nextStates"] || [],
    applicationDocumentUrl: leaveDetails.applicationDocumentUrl || "",
    startDate: leaveDetails.startDate || null,
    endDate: leaveDetails.endDate || null,
    percentageOnDuty: leaveDetails.percentageOnDuty,
    note: leaveDetails.leaveNotes || "",
    notificationRemarks: leaveDetails.notificationRemarks || "",
    leavePercentageIncrease: leaveDetails.leavePercentageIncrease,
    dataSource: DefaultDataSource,
  };
  // **** Form Validation Schema ****
  const LeaveActionFormValidation = Yup.object().shape({
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required"),
    percentageOnDuty: Yup.number().min(0).max(99.999).required("Required"),
    leavePercentageIncrease: Yup.number().min(0).max(99.999),
  });

  // State to indicate Application Document value is formatted URL or not
  const [isURLValidate, setIsURLValidate] = React.useState(false);
  // State to indicate show info details
  const [showInfoDetails, setShowInfoDetails] = React.useState(false);
  //*** To validate the URL format ***
  const checkURLFormat = (fieldValue) => {
    let resultValue = fieldValue.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    resultValue == null ? setIsURLValidate(true) : setIsURLValidate(false);
  };

  // *** Check if the Manual Typed Dates are valid ***
  const checkIfValidDates = (startDate, endDate) => {
    return startDate && endDate
      ? new Date(endDate).getTime() < new Date(startDate).getTime()
      : false;
  };
  // State to indicate Percentage On Duty value is in required format or not
  const [isPercentageOnDutyValid, setIsPercentageOnDutyValid] =
    React.useState(true);

  const [isPercentageIncreaseValid, setIsPercentageIncreaseValid] =
    React.useState(true);

  // *** Check format of Percentage on Duty***
  const checkPercentageFormat = (value, setterFunction) => {
    let splitValue = value.split(".")[1];
    !splitValue || (splitValue && splitValue.length <= 3)
      ? setterFunction(true)
      : setterFunction(false);
  };
  // *** Flag to identify user has made any changes in form or not ***
  const [dirtyFlag, setDirtyFlag] = React.useState(false);

  const [leaveInfoDetails, setLeaveInfoDetails] = React.useState({});
  //** Click handler of Info Icon */
  const handleInfoIconClick = () => {
    getLeaveInfoDetails(setLeaveInfoDetails, setAlert, universityId, actionId);
    setShowInfoDetails(!showInfoDetails);
  };

  const [emailDetails, setEmailDetails] = React.useState({});

  const [fetchGetEmailNotification, setFetchGetEmailNotification] =
    React.useState(false);

  //Handler function to save/update leave details
  const handleFormSubmit = (values) => {
    (actionType === "add" &&
      saveLeaveAction(
        setShowSaveConfirmationMsg,
        setOpenDialog,
        setReloadAudit,
        setAlert,
        setActionId,
        setFetchGetEmailNotification,
        { values, universityId }
      ) &&
      setHasCrossSchoolAppointment(false)) ||
      (actionType === "edit" &&
        updateLeaveAction(
          setShowSaveConfirmationMsg,
          setShowEditLeaveActionDialog,
          setShowInfoDetails,
          setReloadAudit,
          setReloadAccrual,
          setAlert,
          setActionId,
          setFetchGetEmailNotification,
          { values, universityId, actionId }
        ));
  };

  React.useEffect(() => {
    fetchGetEmailNotification &&
      getLeaveEmailNotification(
        universityId,
        actionId,
        setShowEmailNotificationDialog,
        setAlert,
        setEmailDetails,
        setReloadLeave,
        setFetchGetEmailNotification
      );
  }, [
    actionId,
    actionType,
    fetchGetEmailNotification,
    setReloadLeave,
    universityId,
    setAlert,
  ]);

  const showWarningIconValidation = (startDate, endDate, type) => {
    if (startDate && endDate && type) {
      checkCrossSchoolAppointment(
        setHasCrossSchoolAppointment,
        universityId,
        startDate,
        endDate,
        type
      );
    } else {
      setHasCrossSchoolAppointment(false);
    }
  };
  const handleClose = (event) => {
    actionType === "add" && setOpenDialog(false);
    actionType !== "add" && setShowEditLeaveActionDialog(false);
    setHasCrossSchoolAppointment(false);
  };

  const saveClicked = (values) => {
    const leave = {
      startDate: values.startDate,
      endDate: values.endDate,
      type: values.type,
      // only send actionId if editing leave. Somehow this actionId persists from the last time a new leave got added/edited
      actionId: actionType === "add" ? null : actionId,
      percentageOnDuty: values.percentageOnDuty,
      leavePercentageIncrease: values.leavePercentageIncrease,
      dataSource: DefaultDataSource,
    };

    fetchLeaveValidation(
      universityId,
      leave,
      codeToMessage,
      setShowSaveConfirmationMsg,
      showCreateLeaveError,
      setValidationWarning
    );
  };

  const showCreateLeaveError = (error) =>
    setAlert("error", error, false, false);

  const codeToMessage = (messageObj) => {
    if (messageObj.code === "OVRLP_FL") {
      return t(`LeaveAccruedTaken.leaveActionForm.leaveValidationMessage.${messageObj.code}`, {
        startDate: messageObj.value.startDate,
        endData: messageObj.value.endDate,
        percentageOnDuty: messageObj.value.percentageOnDuty,
      });
    } else {
      return t(`LeaveAccruedTaken.leaveActionForm.leaveValidationMessage.${messageObj.code}`);
    }
  }


  const handleCloseEmailNotification = (event) => {
    setShowEmailNotificationDialog(false);
    setReloadLeave(true);
  };
  // To use the makeStyles
  const classes = useStyles({
    buttonsCount:
      (leaveDetails.nextStates && leaveDetails.nextStates.length) || 1,
  });

  // State to store average FTP for last 3 years.
  const [averageFTELast3Years, setAverageFTELast3Years] = React.useState({});
  // *** Function called when startDate updated, will update avaerage FTP ***
  const updateAverageFTELast3Years = React.useCallback(
    (startDate, type) => {
      if (startDate && type === LeaveActionsType.PDL) {
        isDateValid(new Date(startDate)) &&
          getAverageFTELast3Years(
            setAverageFTELast3Years,
            setAlert,
            universityId,
            startDate
          );
      } else {
        setAverageFTELast3Years({});
      }
    },
    [setAlert, universityId]
  );

  const isAverageFTEExists = (type) => {
    if (
      type === LeaveActionsType.PDL &&
      Math.round(averageFTELast3Years.averageFte * 100 + "e+3") + "e-3" < 100
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (actionType === "edit") {
      updateAverageFTELast3Years(
        leaveActionFormValues.startDate,
        leaveActionFormValues.type
      );
    }
  }, [
    leaveActionFormValues.startDate,
    leaveActionFormValues.type,
    actionType,
    updateAverageFTELast3Years,
  ]);

  return (
    <React.Fragment>
      {actionType === "add" && (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <AddCircleIcon />
          &nbsp;&nbsp;{" "}
          {t(
            "LeaveAccruedTaken.leaveHistoryLabels.createLeaveActionButtonLabel"
          )}
        </Button>
      )}
      <Dialog
        open={showEditLeaveActionDialog || openDialog}
        PaperComponent={PaperComponent}
        disableBackdropClick={true}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={handleClose}
        disableEscapeKeyDown={dirtyFlag}
        hideBackdrop={true}
        disableScrollLock={true}
        onEscapeKeyDown={(event) => {
          event.stopPropagation();
          actionType !== "view" && setShowCancelConfirmationMsg(dirtyFlag);
        }}
        classes={{ paperScrollPaper: classes.paperScrollPaper }}
      >
        <DialogTitle
          id="draggable-dialog-title"
          disableTypography
          classes={{ root: classes.dialogTitleRoot }}
        >
          <Grid container justify="space-between">
            <Grid item>
              {(actionType === "add" &&
                t(`${leaveActionFormLabel}.newLeaveActionTitle`)) ||
                (actionType === "edit" &&
                  t(`${leaveActionFormLabel}.editLeaveActionTitle`)) ||
                t(`${leaveActionFormLabel}.viewLeaveActionTitle`)}
            </Grid>
            {hasCrossSchoolAppointment && (
              <Grid>
                <Tooltip
                  interactive
                  title={t(`${leaveActionFormLabel}.tooltipText`)}
                  placement="right"
                >
                  <WarningIcon
                    fontSize="large"
                    classes={{ root: classes.warningIcon }}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Formik
            initialValues={leaveActionFormValues}
            enableReinitialize={true}
            validationSchema={LeaveActionFormValidation}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              handleFormSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                dirty,
                isValid,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                touched,
              } = props;
              return (
                /** Set a flag on change of form values,
                 * so to access that outside the form to show the
                 * 'discard of changes' message
                 **/
                <form
                  onSubmit={handleSubmit}
                  onChange={(event) =>
                    setDirtyFlag(event.target.value ? true : false)
                  }
                >
                  {alert.exists && <ASAlert />}
                  <RadioGroup
                    aria-label="radioGroup"
                    name="type"
                    value={values.type}
                    onChange={(event) => {
                      showWarningIconValidation(
                        values.startDate,
                        values.endDate,
                        event.target.value
                      );
                      handleChange(event);
                      updateAverageFTELast3Years(
                        values.startDate,
                        event.target.value
                      );
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      value={LeaveActionsType.SAB}
                      control={
                        <Radio
                          classes={{ colorSecondary: classes.sabRadio }}
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t(
                        "LeaveAccruedTaken.appointmentSegmentsLabels.SAB"
                      )}
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.disabledLabel,
                      }}
                    />
                    <FormControlLabel
                      value={LeaveActionsType.PDL}
                      control={
                        <Radio
                          classes={{ colorSecondary: classes.pdlRadio }}
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.pdl")}
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.disabledLabel,
                      }}
                    />
                    <FormControlLabel
                      value={LeaveActionsType.LWOS}
                      control={
                        <Radio
                          classes={{ colorSecondary: classes.defaultRadio }}
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.lwos")}
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.disabledLabel,
                      }}
                    />
                    <FormControlLabel
                      value={LeaveActionsType.LOA}
                      control={
                        <Radio
                          classes={{ colorSecondary: classes.defaultRadio }}
                          disabled={actionType === "edit" || disabledFields}
                        />
                      }
                      label={t("LeaveAccruedTaken.accrualLabels.loa")}
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.disabledLabel,
                      }}
                    />
                    <Typography
                      variant="body1"
                      classes={{ root: classes.averageFTELast3Years }}
                    >
                      {values.type === LeaveActionsType.PDL &&
                        values.startDate &&
                        t(`${leaveActionFormLabel}.averageFTELast3Years`, {
                          percentageFTE: averageFTELast3Years.averageFte
                            ? numberToPercentage(
                              averageFTELast3Years.averageFte
                            )
                            : 0,
                        })}
                    </Typography>
                  </RadioGroup>
                  <Grid container direction="column" spacing={2}>
                    <Grid item container>
                      <Grid item container wrap="nowrap" spacing={1}>
                        <Grid
                          item
                          xs={
                            (isAverageFTEExists(values.type) &&
                              actionType === "edit" &&
                              1) ||
                            2
                          }
                        >
                          <Typography variant="subtitle1">
                            {t(
                              `${leaveActionFormLabel}.stateLabels.currentState`
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          spacing={1}
                          className={classes.divMargin}
                        >
                          <Grid
                            item
                            xs={
                              isAverageFTEExists(values.type) &&
                              actionType === "edit" &&
                              1
                            }
                          >
                            <Typography variant="subtitle1">
                              {t(
                                `${leaveActionFormLabel}.stateLabels.nextState`
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Link
                              href={
                                process.env.PUBLIC_URL +
                                "/documents/Leave Action State Transitions.pdf"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <HelpIcon color="primary" />
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container wrap="nowrap">
                        <Grid
                          item
                          xs={(isAverageFTEExists(values.type) && 8) || 12}
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={
                              (isAverageFTEExists(values.type) &&
                                actionType === "add" &&
                                3) ||
                              2
                            }
                          >
                            <ToggleButton
                              size="small"
                              value={values.currentState}
                              disabled
                              selected
                              classes={{
                                root: classes.toggleButtonRoot,
                                label: classes.toggleButtonLabel,
                              }}
                            >
                              {values.currentState}
                            </ToggleButton>
                          </Grid>
                          <Grid item xs={9}>
                            <ToggleButtonGroup
                              size="small"
                              exclusive
                              id="actionState"
                              aria-label="leave action state"
                              value={values.actionState}
                              classes={{ root: classes.toggleButtonGroupRoot }}
                              onChange={(event, value) => {
                                props.setFieldValue("actionState", value);
                              }}
                            >
                              {actionType === "add" && (
                                <ToggleButton
                                  value={values.actionState}
                                  disabled
                                  classes={{
                                    label: classes.toggleButtonLabel,
                                  }}
                                >
                                  {t(LeaveActions[values.actionState])}
                                </ToggleButton>
                              )}
                              {actionType !== "add" &&
                                values.nextStates.map((stateValue, index) => {
                                  return (
                                    <ToggleButton
                                      key={index}
                                      value={stateValue.value}
                                      classes={{
                                        label: classes.toggleButtonLabel,
                                      }}
                                      disabled={disabledFields}
                                    >
                                      {t(LeaveActions[stateValue.value])}
                                    </ToggleButton>
                                  );
                                })}
                            </ToggleButtonGroup>
                          </Grid>
                        </Grid>
                        {isAverageFTEExists(values.type) && values.startDate && (
                          <Grid item xs={4} container>
                            <Grid item xs={11}>
                              <NumberFormat
                                id="leavePercentageIncrease"
                                label={`${t(
                                  `${leaveActionFormLabel}.leavePercentageIncreaseLabel`
                                )}*`}
                                value={values.leavePercentageIncrease}
                                error={
                                  (touched.leavePercentageIncrease &&
                                    !isPercentageIncreaseValid) ||
                                  (touched.leavePercentageIncrease &&
                                    errors.leavePercentageIncrease)
                                }
                                helperText={
                                  (touched.leavePercentageIncrease &&
                                    !isPercentageIncreaseValid) ||
                                    (touched.leavePercentageIncrease &&
                                      errors.leavePercentageIncrease)
                                    ? t(
                                      `${leaveActionFormLabel}.messages.percentageOnLeaveErrorMessage`
                                    )
                                    : ""
                                }
                                customInput={TextField}
                                variant={
                                  (actionType === "edit" && "outlined") ||
                                  inputVariantValue
                                }
                                autoComplete="off"
                                decimalScale={3}
                                onChange={(event, value) => {
                                  props.setFieldValue(
                                    "leavePercentageIncrease",
                                    event.target.value
                                  );
                                  checkPercentageFormat(
                                    event.target.value,
                                    setIsPercentageIncreaseValid
                                  );
                                }}
                                className={classes.textFieldWidth}
                                onBlur={handleBlur}
                                allowNegative={false}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                  classes: {
                                    root: classes.standardInputColor,
                                  },
                                }}
                                disabled={
                                  (actionType === "edit" &&
                                    !leaveDetails.dateEditable) ||
                                  disabledFields
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className={classes.averageFTEHelpIcon}
                            >
                              <Tooltip
                                title={t(
                                  `${leaveActionFormLabel}.leavePercentageIncreaseTooltipText`
                                )}
                              >
                                <HelpIcon color="primary" />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="applicationDocumentUrl"
                        label={t(
                          `${leaveActionFormLabel}.applicationDocumentFieldLabel`
                        )}
                        onChange={(event) => {
                          checkURLFormat(event.target.value);
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 250 }}
                        value={values.applicationDocumentUrl}
                        error={touched.applicationDocumentUrl && isURLValidate}
                        helperText={
                          touched.applicationDocumentUrl && isURLValidate
                            ? t(
                              `${leaveActionFormLabel}.messages.applicationURLValidationMsg`
                            )
                            : ""
                        }
                        disabled={
                          (actionType === "edit" &&
                            !leaveDetails.urlEditable) ||
                          disabledFields
                        }
                        variant={
                          (actionType === "edit" &&
                            leaveDetails.urlEditable &&
                            "outlined") ||
                          inputVariantValue
                        }
                        InputProps={{
                          classes: {
                            root: classes.standardInputColor,
                          },
                        }}
                      />
                    </Grid>
                    <Grid container item>
                      <Grid item xs={4}>
                        <Grid item xs={11}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              label={`${t(
                                `${leaveActionFormLabel}.startDateLabel`
                              )}*`}
                              name="startDate"
                              value={values.startDate}
                              inputValue={values.startDate}
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 250,
                              }}
                              clearable
                              format="yyyy-MM-dd"
                              id="date-picker-inline-start-from"
                              KeyboardButtonProps={{
                                "aria-label": "start date",
                              }}
                              onChange={(e, date) => {
                                props.setFieldValue("startDate", date);
                                isDateValid(new Date(date)) &&
                                  !checkIfValidDates(date, values.endDate) &&
                                  showWarningIconValidation(
                                    date,
                                    values.endDate,
                                    values.type
                                  );
                                updateAverageFTELast3Years(date, values.type);
                              }}
                              onBlur={handleBlur}
                              maxDateMessage={`${t(
                                "globals.form.dateValidationMessages.startDateValidation"
                              )}`}
                              maxDate={
                                (values.endDate &&
                                  isDateValid(new Date(values.endDate)) &&
                                  format(
                                    addMinutes(
                                      new Date(values.endDate),
                                      new Date(
                                        values.endDate
                                      ).getTimezoneOffset()
                                    ),
                                    "yyyy-MM-dd HH:mm:ss"
                                  )) ||
                                undefined
                              }
                              disabled={
                                (actionType === "edit" &&
                                  !leaveDetails.dateEditable) ||
                                disabledFields
                              }
                              keyboardIcon={
                                actionType === "view" ? false : undefined
                              }
                              inputVariant={
                                (actionType === "edit" &&
                                  leaveDetails.dateEditable &&
                                  "outlined") ||
                                inputVariantValue
                              }
                              InputProps={{
                                classes: {
                                  root: classes.standardInputColor,
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item xs={11}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              label={`${t(
                                `${leaveActionFormLabel}.endDateLabel`
                              )}*`}
                              name="endDate"
                              value={values.endDate}
                              inputValue={values.endDate}
                              inputProps={{ autoComplete: "off" }}
                              clearable
                              format="yyyy-MM-dd"
                              id="date-picker-inline-start-from"
                              KeyboardButtonProps={{
                                "aria-label": "end date",
                              }}
                              onChange={(e, date) => {
                                props.setFieldValue("endDate", date);
                                isDateValid(new Date(date)) &&
                                  !checkIfValidDates(values.startDate, date) &&
                                  showWarningIconValidation(
                                    values.startDate,
                                    date,
                                    values.type
                                  );
                              }}
                              onBlur={handleBlur}
                              minDateMessage={`${t(
                                "globals.form.dateValidationMessages.endDateValidation"
                              )}`}
                              minDate={
                                (values.startDate &&
                                  isDateValid(new Date(values.startDate)) &&
                                  FromDateStrToFLADateTimeStr(
                                    values.startDate
                                  )) ||
                                undefined
                              }
                              disabled={
                                (actionType === "edit" &&
                                  !leaveDetails.dateEditable) ||
                                disabledFields
                              }
                              keyboardIcon={
                                actionType === "view" ? false : undefined
                              }
                              inputVariant={
                                (actionType === "edit" &&
                                  leaveDetails.dateEditable &&
                                  "outlined") ||
                                inputVariantValue
                              }
                              InputProps={{
                                classes: {
                                  root: classes.standardInputColor,
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <NumberFormat
                          id="percentageOnDuty"
                          label={`${t(
                            `${leaveActionFormLabel}.percentageOnDutyLabel`
                          )}*`}
                          value={values.percentageOnDuty}
                          error={
                            (touched.percentageOnDuty &&
                              !isPercentageOnDutyValid) ||
                              (touched.percentageOnDuty &&
                                errors.percentageOnDuty)
                              ? true
                              : false
                          }
                          helperText={
                            (touched.percentageOnDuty &&
                              !isPercentageOnDutyValid) ||
                              (touched.percentageOnDuty &&
                                errors.percentageOnDuty)
                              ? t(
                                `${leaveActionFormLabel}.messages.percentageOnLeaveErrorMessage`
                              )
                              : ""
                          }
                          customInput={TextField}
                          variant={
                            (actionType === "edit" &&
                              leaveDetails.percentageEditable &&
                              "outlined") ||
                            inputVariantValue
                          }
                          autoComplete="off"
                          decimalScale={3}
                          onChange={(event, value) => {
                            props.setFieldValue(
                              "percentageOnDuty",
                              event.target.value
                            );
                            checkPercentageFormat(
                              event.target.value,
                              setIsPercentageOnDutyValid
                            );
                          }}
                          onBlur={handleBlur}
                          allowNegative={false}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            classes: {
                              root: classes.standardInputColor,
                            },
                          }}
                          disabled={
                            (actionType === "edit" &&
                              !leaveDetails.percentageEditable) ||
                            disabledFields
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="note"
                        label={t(
                          `${leaveActionFormLabel}.leaveActionNotesFieldLabel`
                        )}
                        multiline
                        fullWidth
                        variant={
                          (actionType === "view" && "standard") || "outlined"
                        }
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                        disabled={actionType === "view"}
                        InputProps={{
                          classes: {
                            root: classes.standardInputColor,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="notificationRemarks"
                        label={t(
                          `${leaveActionFormLabel}.notificationRemarksFieldLabel`
                        )}
                        multiline
                        fullWidth
                        variant={
                          (actionType === "view" && "standard") || "outlined"
                        }
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notificationRemarks}
                        disabled={actionType === "view"}
                        InputProps={{
                          classes: {
                            root: classes.standardInputColor,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                    <Grid container>
                      {leaveDetails.infoAvailable && (
                        <IconButton
                          classes={{ root: classes.infoIconButton }}
                          onClick={() => handleInfoIconClick()}
                        >
                          <InfoIcon color="primary" />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={8}
                      spacing={2}
                      justify="flex-end"
                      wrap="nowrap"
                    >
                      {(actionType !== "view" && (
                        <React.Fragment>
                          <Grid container item xs={8}>
                            <Button
                              onClick={() => {
                                dirty
                                  ? setShowCancelConfirmationMsg(true)
                                  : (setOpenDialog(false) ||
                                    (setShowEditLeaveActionDialog &&
                                      setShowEditLeaveActionDialog(false)) ||
                                    setShowInfoDetails(false)) &&
                                  setHasCrossSchoolAppointment(false);
                              }}
                              fullWidth={true}
                              variant="outlined"
                              color="primary"
                            >
                              {t("globals.form.actionButtons.cancel")}
                            </Button>
                            <ASConfirmation
                              open={showCancelConfirmationMsg}
                              message={t(
                                `${leaveActionFormLabel}.messages.cancelConfirmationMessage`
                              )}
                              handleCancel={() => {
                                setShowCancelConfirmationMsg(false);
                              }}
                              handleOk={() => {
                                setShowCancelConfirmationMsg(false);
                                setHasCrossSchoolAppointment(false);
                                setOpenDialog(false);
                                setShowEditLeaveActionDialog &&
                                  setShowEditLeaveActionDialog(false);
                                setShowInfoDetails(false);
                              }}
                              okLabel={t("globals.form.actionButtons.yes")}
                              cancelLabel={t("globals.form.actionButtons.no")}
                            />
                          </Grid>
                          <Grid item container xs={8}>
                            <Button
                              fullWidth={true}
                              variant="contained"
                              onClick={() => saveClicked(values)}
                              color="primary"
                              disabled={
                                !dirty ||
                                !isValid ||
                                checkIfValidDates(
                                  values.startDate,
                                  values.endDate
                                )
                              }
                            >
                              {t("globals.form.actionButtons.save")}
                            </Button>
                            <SpeedBumpDialog
                              warning={validationWarning}
                              values={values}
                              open={showSaveConfirmationMsg}
                              handleCancel={() =>
                                setShowSaveConfirmationMsg(false)
                              }
                              handleOk={handleSubmit}
                            />
                          </Grid>
                        </React.Fragment>
                      )) || (
                          <Grid item container xs={6}>
                            <Button
                              fullWidth={true}
                              variant="contained"
                              onClick={() => {
                                setShowEditLeaveActionDialog(false);
                                setShowInfoDetails(false);
                              }}
                              color="primary"
                            >
                              {t("globals.form.actionButtons.close")}
                            </Button>
                          </Grid>
                        )}
                    </Grid>
                  </DialogActions>
                  {leaveDetails.infoAvailable && showInfoDetails && (
                    <React.Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Table size="small">
                            <TableBody>
                              <TableRow></TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCellRoot }}
                                  width="60%"
                                >
                                  {t(
                                    `${leaveActionFormLabel}.accrualBegBalanceLabel`
                                  )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  classes={{ root: classes.tableCellRoot }}
                                  width="40%"
                                >
                                  {leaveInfoDetails.accruedBegBalance &&
                                    FormatNegativeValues(
                                      leaveInfoDetails.accruedBegBalance,
                                      classes.negativeNumberColor
                                    )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCellRoot }}
                                >
                                  {t(`${leaveActionFormLabel}.leaveTakenLabel`)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  classes={{ root: classes.tableCellRoot }}
                                >
                                  {leaveInfoDetails.leaveTaken &&
                                    FormatNegativeValues(
                                      leaveInfoDetails.leaveTaken,
                                      classes.negativeNumberColor
                                    )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCellRoot }}
                                >
                                  {t(
                                    `${leaveActionFormLabel}.accrualEndBalanceLabel`
                                  )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  classes={{ root: classes.tableCellRoot }}
                                >
                                  {leaveInfoDetails.accruedEndBalance &&
                                    FormatNegativeValues(
                                      leaveInfoDetails.accruedEndBalance,
                                      classes.negativeNumberColor
                                    )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          direction="column"
                          justify="space-around"
                          classes={{ item: classes.italicGrid }}
                        >
                          <Grid item>
                            {t(
                              `${leaveActionFormLabel}.projectedStartDateLabel`
                            )}
                          </Grid>
                          <Grid item>{/* empty row */}</Grid>
                          <Grid item>
                            {t(`${leaveActionFormLabel}.projectedEndDateLabel`)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <EmailNotification
        open={showEmailNotificationDialog}
        onClose={handleCloseEmailNotification}
        emailDetails={emailDetails}
        setReloadLeave={setReloadLeave}
        universityId={universityId}
        actionId={actionId}
        setShowEmailNotificationDialog={setShowEmailNotificationDialog}
      />
    </React.Fragment>
  );
};

const SpeedBumpDialog = (props) => {
  const { t } = useTranslation();
  const { values, open, handleOk, handleCancel, warning } = props;
  const styles = useStyles();

  const getEditSaveConfirmationWarning = () => {
    if (!warning) {
      // no warning -> don't show title
      return "";
    }

    return (
      <span className={styles.validationWarning}>
        <WarningIcon
          fontSize="large"
          style={{
            position: "relative",
            top: "7px",
          }}
        />
        &nbsp;{warning}
      </span>
    );
  };

  return (
    <ASConfirmation
      title={getEditSaveConfirmationWarning()}
      open={open}
      message={t(
        `LeaveAccruedTaken.leaveActionForm.messages.saveConfirmationMessage`,
        {
          category: values.type,
          nextState: t(LeaveActions[values.actionState]),
        }
      )}
      handleCancel={handleCancel}
      handleOk={handleOk}
      okLabel={t("globals.form.actionButtons.yes")}
      cancelLabel={t("globals.form.actionButtons.no")}
    />
  );
};
