import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chipRoot: {
    border: "0.8px solid",
    borderColor: theme.palette.grey[400],
    borderRadius: "4px",
    marginTop: "6px",
  },
}));
