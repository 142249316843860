import { makeStyles } from "@material-ui/core/styles";
import { SegmentsColor } from "../../../constants";

export const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    border: "none",
  },
  tableHeadCell: {
    fontWeight: 600,
    fontSize: "17px",
  },
  tableCellEllipsis: {
    overflow: "hidden",
  },
  body1: {
    fontStyle: "italic",
  },
  rowColor: {
    background: theme.palette.grey[300],
  },
  textSABColor: {
    color: SegmentsColor["SAB_DARK"],
  },
  textPDLColor: {
    color: SegmentsColor["PDL_DARK"],
  },
  denseTablePadding: {
    padding: "8px",
  },
}));
