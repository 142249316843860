import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "100%",
    maxWidth: "720px",
  },
  dialogTitleRoot: {
    paddingBottom: "0px",
  },
  dialogContentRoot: {
    padding: "0px 24px 16px 26px",
  },
  dialogActionsRoot: {
    padding: "16px 0px 0px",
  },
}));
