import { format, addMinutes } from "date-fns";

/* Function to format a date string in yyyy-MM-dd format.
 */
export const FromDateStrToFLADateStr = (datestr) => {
  return datestr
    ? format(
        addMinutes(new Date(datestr), new Date(datestr).getTimezoneOffset()),
        "yyyy-MM-dd"
      )
    : "";
};

/* Function to format a date string in yyyy-MM-dd HH:mm:ss format.
 */
export const FromDateStrToFLADateTimeStr = (datestr) => {
  return datestr
    ? format(
        addMinutes(new Date(datestr), new Date(datestr).getTimezoneOffset()),
        "yyyy-MM-dd HH:mm:ss"
      )
    : "";
};

export const FromUTCDateStrToFLADateTimeStr = (datestr) => {
  return datestr
    ? format(new Date(datestr.toLocaleString()), "yyyy-MM-dd HH:mm:ss")
    : "";
};
