import { makeStyles } from "@material-ui/core/styles";

const SAB_COLOR = "#BE8DDA";
const PDL_COLOR = "#7ADFA9";
const DEFAULT_COLOR = "#666666";

export const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "100%",
    maxWidth: "720px",
  },
  dialogTitleRoot: {
    paddingBottom: "0px",
  },
  radioLabelRoot: {
    marginRight: "30px",
    "& .MuiRadio-root": {
      padding: "4px",
    },
  },
  sabRadio: {
    "&.Mui-checked": {
      color: SAB_COLOR,
    },
  },
  pdlRadio: {
    "&.Mui-checked": {
      color: PDL_COLOR,
    },
  },
  defaultRadio: {
    "&.Mui-checked": {
      color: DEFAULT_COLOR,
    },
  },
  dialogContentRoot: {
    padding: "0px 24px 16px 26px",
  },
  toggleButtonRoot: {
    borderRadius: "8px",
    width: "98%",
  },
  toggleButtonGroupRoot: (props) => ({
    display: "flex",
    "& .MuiToggleButton-root": {
      width: (props.buttonsCount < 4 && "29%") || "42%",
      "&:last-of-type ": {
        borderTopLeftRadius: (props.buttonsCount === 1 && "8px") || "0px",
        borderBottomLeftRadius: (props.buttonsCount === 1 && "8px") || "0px",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
      "&:first-of-type ": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
    },
  }),
  toggleButtonLabel: {
    fontSize: "15px",
    color: theme.palette.common.black,
    textTransform: "none",
  },
  dialogActionsRoot: {
    padding: "16px 0px 0px",
  },
  infoIconButton: {
    padding: "0px",
  },
  tableCellRoot: {
    border: "1px solid",
    borderColor: theme.palette.grey[400],
  },
  tableCellPadding: {
    "&:last-of-type ": {
      paddingRight: "0px",
    },
  },
  italicGrid: {
    fontStyle: "italic",
    color: theme.palette.grey[700],
  },
  standardInputColor: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "&.MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
  },
  disabledLabel: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  negativeNumberColor: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginTop: "5px",
  },
  averageFTELast3Years: {
    margin: "3px auto",
  },
  averageFTEHelpIcon: { alignSelf: "center" },
  textFieldWidth: { width: "98%" },
  divMargin: { marginLeft: "12px" },

  validationWarning: {
    color: theme.palette.warning.main,
  },
}));
