import React from "react";
/** Custom Component **/
import { FromDateStrToFLADateStr } from "../../Utils/FormatDateValues";
/** Material UI Imports **/
import { Grid, Tooltip, Typography, Divider } from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";
/** Custom Styles **/
import { useStyles } from "./LeaveAccruedTaken.styles";

import { useTranslation } from "react-i18next";

//Function to create the Current Appointments panel detail
export const CurrentAppointments = ({ facultyDetails }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for translation key
  const currentApptDetailsLabels = "LeaveAccruedTaken.currentApptLabels";
  const classes = useStyles();

  return (
    (facultyDetails.appointments &&
      facultyDetails.appointments.length > 0 &&
      facultyDetails.appointments.map((row, index) => (
        <React.Fragment key={index}>
          <Grid container key={index}>
            <Grid container item>
              <Typography color="textPrimary" variant="h2">
                {row.tier}
              </Typography>
            </Grid>
            <Grid container item xs={11}>
              <Grid item xs={5}>
                <Grid container item xs={11}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptFTE`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {row.apptFte}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.department`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid container item wrap="nowrap" xs={6}>
                    <Tooltip
                      title={(row.department && row.department.name) || ""}
                      placement="bottom-start"
                      interactive
                    >
                      <Typography
                        color="textPrimary"
                        classes={{ body1: classes.typographySemiBold }}
                        noWrap
                      >
                        {row.department && row.department.name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.rank`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item container wrap="nowrap" xs={6}>
                    <Tooltip
                      title={row.rank}
                      placement="bottom-start"
                      interactive
                    >
                      <Typography
                        color="textPrimary"
                        classes={{ body1: classes.typographySemiBold }}
                        noWrap
                      >
                        {row.rank}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container item xs={11}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptStart`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {" "}
                      {FromDateStrToFLADateStr(row.startDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.school`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item container xs={9} spacing={1}>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        classes={{ body1: classes.typographySemiBold }}
                      >
                        {row.school.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!row.schoolOfMedicine && (
                        <Tooltip
                          interactive
                          title={t(
                            `${currentApptDetailsLabels}.schoolTooltipText`
                          )}
                          placement="right"
                        >
                          <WarningIcon
                            fontSize="small"
                            classes={{ root: classes.warningIcon }}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.division`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid container item xs={8} wrap="nowrap">
                    {row.division && (
                      <Tooltip
                        title={row.division.name || ""}
                        placement="bottom-start"
                        interactive
                      >
                        <Typography
                          color="textPrimary"
                          classes={{ body1: classes.typographySemiBold }}
                          noWrap
                        >
                          {row.division.name}
                        </Typography>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.line`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {row.facultyLine.abbreviation} {row.facultyLine.text}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography color="textPrimary">
                      {t(`${currentApptDetailsLabels}.apptEnd`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {FromDateStrToFLADateStr(row.endDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {index < facultyDetails.appointments.length - 1 && (
            <Divider classes={{ root: classes.divider }} />
          )}
        </React.Fragment>
      ))) ||
    null
  );
};
