import React from "react";
import { TextField, FormControl, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ResetFilter } from "../../components/Layout/FilterPanel/ResetFilter";
import { useTranslation } from "react-i18next";
import {
  getInitialLineDepartmentOptions,
  getDivisionOptionsFromDepartments,
} from "../../services/facultyLeaveFilterService";
import { useGetPersonOptions } from "../../services/autocompleteService";
import { Check as CheckIcon } from "@material-ui/icons";
import { useStyles } from "./FacultyLeave.filters.styles";
import {
  LeaveTypes,
  Status,
  AUTOCOMPLETE_MINIMUM_CHARS,
} from "../../constants";
import { useFacultyLeaveContext } from "../../services/facultyLeaveContext";

export const FacultyLeaveFilter = ({ location, history, changeFilterData }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // Common string for all translation keys
  const facultyLeaveFilterLabels = "FLA.filterPanel.options";
  const { loadAutocomplete } = useGetPersonOptions();
  const classes = useStyles();
  // Call to Context
  const {
    facultyLeaveFiltersPreference,
    setFacultyLeaveFiltersPreference,
    setPagination,
    pagination,
  } = useFacultyLeaveContext();

  /** State variable for university id value of person filter **/
  const [universityId, setUniversityId] = React.useState(
    facultyLeaveFiltersPreference.universityId
  );

  /**  State for person,department,line,status and category dropdown Input values **/
  const [filterPersonInputValue, setFilterPersonInputValue] =
    React.useState("");
  const [departmentFilterInputValue, setDepartmentFilterInputValue] =
    React.useState([]);
  const [divisionFilterInputValue, setDivisionFilterInputValue] =
    React.useState([]);
  const [filterLineInputValue, setFilterLineInputValue] = React.useState([]);
  const [statusInputValue, setStatusInputValue] = React.useState([
    Status["Active"],
  ]);
  const [apptTypeInputValue, setApptTypeInputValue] = React.useState([
    LeaveTypes["SAB"],
    LeaveTypes["PDL"],
  ]);

  /** State for person,department,line dropdown options **/
  const [personOptions, setPersonOptions] = React.useState([]);
  const [lineOptions, setLineOptions] = React.useState([]);
  const [departmentOptions, setDepartmentOptions] = React.useState([]);
  const [divisionOptions, setDivisionOptions] = React.useState([]);
  // State for Filter errors
  const [personFilterError, setPersonFilterError] = React.useState({
    exist: false,
    message: null,
  });

  const [prefetchFiltersError, setPrefetchFiltersError] = React.useState({
    exist: false,
    message: null,
  });
  // Function to reset the Filter value
  const resetFn = () => {
    setFilterPersonInputValue("");
    setPersonOptions([]);
    setDepartmentFilterInputValue([]);
    setDivisionFilterInputValue([]);
    setFilterLineInputValue([]);
    setStatusInputValue([Status["Active"]]);
    setApptTypeInputValue([LeaveTypes["SAB"], LeaveTypes["PDL"]]);
    setFacultyLeaveFiltersPreference({
      universityId: "",
      department: "",
      division: "",
      line: "",
      status: "Active",
      apptType: "SAB,PDL",
    });
    setPrefetchFiltersError({
      exist: false,
      message: null,
    });
    setPersonFilterError({
      exist: false,
      message: null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // Function to clear the Filter value
  const clearFn = () => {
    setFilterPersonInputValue("");
    setPersonOptions([]);
    setDepartmentFilterInputValue([]);
    setDivisionFilterInputValue([]);
    setFilterLineInputValue([]);
    setStatusInputValue([]);
    setApptTypeInputValue([]);
    setFacultyLeaveFiltersPreference({
      universityId: "",
      department: "",
      division: "",
      line: "",
      status: "",
      apptType: null,
    });
    setPrefetchFiltersError({
      exist: false,
      message: null,
    });
    setPersonFilterError({
      exist: false,
      message: null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // Fetch person filter options, when URL params includes its value
  const [loadPersonOptions, setLoadPersonOptions] = React.useState(false);

  if (facultyLeaveFiltersPreference.universityId && !loadPersonOptions) {
    loadAutocomplete(
      setPersonOptions,
      setPersonFilterError,
      facultyLeaveFiltersPreference.universityId
    );
    setLoadPersonOptions(true);
  }

  /** Call to service for prefetch line and department filters options */
  React.useEffect(() => {
    getInitialLineDepartmentOptions(
      setLineOptions,
      setDepartmentOptions,
      setPrefetchFiltersError
    );
  }, []);

  /*Whenever URL parameters already contain the Query String for
   *"Line of Appointment","Person" and "Department" then
   *this methods will return those objects for selected options
   *to set the corresponding options in the field.
   */
  const personInputValueByURL = personOptions
    .filter(({ value }) => value["universityId"] === universityId)
    .reduce((acc, val) => (acc += val.displayText), "");

  // TextField onChange Handler for Person Autocomplete
  const handlePersonAutocompleteChange = (personInput) => {
    setFilterPersonInputValue(personInput);
    setUniversityId(personInput);

    if (personInput && personInput.length >= AUTOCOMPLETE_MINIMUM_CHARS) {
      loadAutocomplete(setPersonOptions, setPersonFilterError, personInput);
    }
    if (!personInput) {
      setFacultyLeaveFiltersPreference({
        ...facultyLeaveFiltersPreference,
        universityId: "",
      });
    }
  };

  // onChange Handler for Department Autocomplete
  const handleDepartmentAutocompleteChange = (departmentValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      department: departmentValue.map((option) => option.code).toString(),
    });

    setPagination({
      ...pagination,
      page: 0,
    });
    setDepartmentFilterInputValue(departmentValue.map((option) => option));
    /** Call to service to fetch division filters options */
    getDivisionOptionsFromDepartments(
      departmentValue.map((option) => option.code).toString(),
      setDivisionOptions,
      setDivisionFilterInputValue,
      setPrefetchFiltersError
    );
    // Clear Data When No Department Value Selected.
    if (
      !divisionOptions.length &&
      facultyLeaveFiltersPreference.division.length
    ) {
      setFacultyLeaveFiltersPreference({
        ...facultyLeaveFiltersPreference,
        division: "",
      });
    }
  };

  // onChange Handler for Division
  const handleDivisionAutocompleteChange = (divisionValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      division: divisionValue.map((option) => option.code).toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
    setDivisionFilterInputValue(divisionValue.map((option) => option));
  };
  // onChange Handler for Line Autocomplete
  const handleLineAutocompleteChange = (lineValue) => {
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      line: lineValue.map((option) => option.value.abbreviation).toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
    setFilterLineInputValue(lineValue.map((option) => option));
  };

  // onChange Handler for Status/Active/Inactive Autocomplete
  const handleStatusAutocompleteChange = (statusValue) => {
    setStatusInputValue(statusValue);
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      status: Object.keys(Status)
        .filter((optionKey) => statusValue.includes(Status[optionKey]))
        .toString(),
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // onChange Handler for Appt. Category Autocomplete
  const handleApptTypeAutocompleteChange = (apptTypeValue) => {
    setApptTypeInputValue(apptTypeValue);
    setFacultyLeaveFiltersPreference({
      ...facultyLeaveFiltersPreference,
      apptType: apptTypeValue.length
        ? Object.keys(LeaveTypes)
            .filter((optionKey) =>
              apptTypeValue.includes(LeaveTypes[optionKey])
            )
            .toString()
        : null,
    });
    setPagination({
      ...pagination,
      page: 0,
    });
  };

  // Return JSX to display selected options with check icon
  const getSelectedOptionsWithCheckIcon = (
    options,
    selectedOptions,
    selectedOptionsByURL
  ) => {
    return (
      <Grid container spacing={2} wrap="nowrap">
        <Grid item>{options.displayText}</Grid>
        <Grid item>
          {((selectedOptions.length && selectedOptions.includes(options)) ||
            selectedOptionsByURL.includes(options)) && <CheckIcon />}
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <FormControl variant="outlined">
        <Autocomplete
          id="personFilter"
          options={personOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          inputValue={filterPersonInputValue || personInputValueByURL}
          clearOnEscape={true}
          onOpen={(event) => {
            if (personOptions.length) {
              setPersonOptions([]);
              !filterPersonInputValue &&
                setFacultyLeaveFiltersPreference({
                  ...facultyLeaveFiltersPreference,
                  universityId: "",
                });
            }
          }}
          onChange={(e, value) => {
            setFacultyLeaveFiltersPreference({
              ...facultyLeaveFiltersPreference,
              universityId: (value && value.value.universityId) || "",
            });
            setFilterPersonInputValue((value && value.displayText) || "");
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          closeIcon={false}
          filterSelectedOptions={true}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.person.label`)}
              variant="outlined"
              onChange={(e) => handlePersonAutocompleteChange(e.target.value)}
              error={personFilterError.exist}
              helperText={personFilterError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          ChipProps={{ classes: { root: classes.chipRoot } }}
          id="departmentFilter"
          multiple
          value={
            (departmentFilterInputValue.length && departmentFilterInputValue) ||
            departmentInputValueByURL(
              departmentOptions,
              facultyLeaveFiltersPreference
            )
          }
          options={departmentOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          renderOption={(option) => {
            return getSelectedOptionsWithCheckIcon(
              option,
              departmentFilterInputValue,
              departmentInputValueByURL(
                departmentOptions,
                facultyLeaveFiltersPreference
              )
            );
          }}
          clearOnEscape={true}
          onChange={(e, value) => handleDepartmentAutocompleteChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.department.label`)}
              variant="outlined"
              error={prefetchFiltersError.exist}
              helperText={prefetchFiltersError.message}
            />
          )}
        />
      </FormControl>
      {divisionOptions && divisionOptions.length ? (
        <FormControl variant="outlined">
          <Autocomplete
            ChipProps={{ classes: { root: classes.chipRoot } }}
            id="divisionFilter"
            multiple
            value={
              (divisionFilterInputValue.length && divisionFilterInputValue) ||
              divisionInputValueByURL(
                divisionOptions,
                facultyLeaveFiltersPreference
              )
            }
            options={divisionOptions || []}
            getOptionLabel={(option) => option && option.displayText}
            renderOption={(option) => {
              return getSelectedOptionsWithCheckIcon(
                option,
                divisionFilterInputValue,
                divisionInputValueByURL(
                  divisionOptions,
                  facultyLeaveFiltersPreference
                )
              );
            }}
            clearOnEscape={true}
            onChange={(e, value) => handleDivisionAutocompleteChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(`${facultyLeaveFilterLabels}.division.label`)}
                variant="outlined"
                error={prefetchFiltersError.exist}
                helperText={prefetchFiltersError.message}
              />
            )}
          />
        </FormControl>
      ) : null}
      <FormControl variant="outlined">
        <Autocomplete
          ChipProps={{ classes: { root: classes.chipRoot } }}
          id="lineFilter"
          multiple
          value={
            (filterLineInputValue.length && filterLineInputValue) ||
            lineInputValueByURL(lineOptions, facultyLeaveFiltersPreference)
          }
          options={lineOptions || []}
          getOptionLabel={(option) => option && option.displayText}
          renderOption={(option) => {
            return getSelectedOptionsWithCheckIcon(
              option,
              filterLineInputValue,
              lineInputValueByURL(lineOptions, facultyLeaveFiltersPreference)
            );
          }}
          clearOnEscape={true}
          onChange={(e, value) => handleLineAutocompleteChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.line.label`)}
              variant="outlined"
              error={prefetchFiltersError.exist}
              helperText={prefetchFiltersError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="activeFilter"
          ChipProps={{ classes: { root: classes.chipRoot } }}
          value={statusInputValue || []}
          multiple
          options={Object.values(Status)}
          getOptionLabel={(option) => t(option)}
          renderOption={(option) => {
            return (
              <Grid container spacing={2}>
                <Grid item>{t(option)}</Grid>
                <Grid item>
                  {statusInputValue && statusInputValue.includes(option) && (
                    <CheckIcon />
                  )}
                </Grid>
              </Grid>
            );
          }}
          clearOnEscape={true}
          onChange={(e, value) => handleStatusAutocompleteChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.activeInactive.label`)}
              variant="outlined"
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="categoryFilter"
          ChipProps={{ classes: { root: classes.chipRoot } }}
          multiple
          value={apptTypeInputValue}
          options={Object.values(LeaveTypes)}
          getOptionLabel={(option) =>
            option === "LeaveAccruedTaken.appointmentSegmentsLabels.PDL"
              ? t("LeaveAccruedTaken.accrualLabels.pdl")
              : t(option)
          }
          renderOption={(option) => {
            return (
              <Grid container spacing={2}>
                <Grid item>
                  {option === "LeaveAccruedTaken.appointmentSegmentsLabels.PDL"
                    ? t("LeaveAccruedTaken.accrualLabels.pdl")
                    : t(option)}
                </Grid>
                <Grid item>
                  {apptTypeInputValue &&
                    apptTypeInputValue.includes(option) && <CheckIcon />}
                </Grid>
              </Grid>
            );
          }}
          clearOnEscape={true}
          onChange={(e, value) => handleApptTypeAutocompleteChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`${facultyLeaveFilterLabels}.category.label`)}
              variant="outlined"
            />
          )}
        />
      </FormControl>
      <ResetFilter resetFn={resetFn} clearFn={clearFn} />
    </>
  );
};

/* Get Input Value By URL for Line of Appointment */
const lineInputValueByURL = (lineOptions, facultyLeaveFiltersPreference) =>
  lineOptions
    .filter(({ value }) =>
      facultyLeaveFiltersPreference.line.includes(value["abbreviation"])
    )
    .map((value) => value);

/* Get Input Value By URL for Department Filter */
const departmentInputValueByURL = (
  departmentOptions,
  facultyLeaveFiltersPreference
) =>
  departmentOptions
    .filter((value) =>
      facultyLeaveFiltersPreference.department.includes(value["code"])
    )
    .map((value) => value);

/* Get Input Value By URL for Division Filter */
const divisionInputValueByURL = (
  divisionOptions,
  facultyLeaveFiltersPreference
) =>
  divisionOptions
    .filter((value) =>
      facultyLeaveFiltersPreference.division.includes(value["code"])
    )
    .map((value) => value);
