import React from "react";
/** Material UI Imports **/
import {
  Breadcrumbs,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { Warning as WarningIcon, Help as HelpIcon } from "@material-ui/icons";

/** Custom Styles **/
import { useStyles } from "./LeaveAccruedTaken.styles";
/** Import of expansion panels on Leave Accrued Taken page **/
import { LeaveAccruedTakenAccordion } from "./LeaveAccruedTakenAccordion/LeaveAccruedTakenAccordion";
import { CurrentAppointments } from "./CurrentAppointments";
import { AppointmentHistory } from "./AppointmentHistory";
import { AppointmentSegments } from "./AppointmentSegments";
import { AccrualSubPanels } from "./Accrual";
import { Leave } from "./Leave";
import { Audit } from "./Audit";
import { CreateEditViewLeaveActions } from "./LeaveActions/CreateEditViewLeaveActions";
import { CreateIndividualMilestone } from "./Milestone/CreateIndividualMilestone";

import { useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/** Service Imports **/
import {
  getFacultyDetails,
  getAccruals,
} from "../../services/leaveAccrualTakenService";
/* Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { numberToPercentage } from "../../Utils/FormatNumericValues";

export const LeaveAccruedTaken = () => {
  //Get universityId from url params
  const { universityId } = useParams();
  const location = useLocation();
  /* State to be set in service */
  const [facultyDetails, setFacultyDetails] = React.useState({});
  const [accruals, setAccruals] = React.useState([]);

  /* State to indicate, reload data of Leave section */
  const [reloadLeave, setReloadLeave] = React.useState(false);
  /* State to indicate, reload data of Appointment Segments */
  const [reloadAppointmentSegments, setReloadAppointmentSegments] =
    React.useState(false);
  /* State to indicate, reload data of Audit */
  const [reloadAudit, setReloadAudit] = React.useState(false);
  /* State to indicate, reload data of Acccrual */
  const [reloadAccrual, setReloadAccrual] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [accrualsLoading, setAccrualsLoading] = React.useState(false);
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlertContext();
  /* State to indicate, that error occurs on Leave Accrued-Taken page and not on Dialogs*/
  const [pageError, setPageError] = React.useState(false);
  const [expandAllAccrual, setExpandAllAccrual] = React.useState(false);
  const [leaveNotesContent, setLeaveNotesContent] = React.useState("");
  const [isLeaveNoteEditable, setIsLeaveNoteEditable] = React.useState(false);

  // State for Leave Section Notes Dialog
  const [showLeaveSectionNotesDialog, setShowLeaveSectionNotesDialog] =
    React.useState(false);

  React.useEffect(() => {
    if (!Object.keys(facultyDetails).length) {
      getFacultyDetails(
        setFacultyDetails,
        setLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
      getAccruals(
        setAccruals,
        setAccrualsLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
    }
    if (reloadAccrual) {
      getAccruals(
        setAccruals,
        setAccrualsLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
      setReloadAccrual(false);
    }
  }, [universityId, reloadAccrual, facultyDetails, clearAlert, setAlert]);
  const leaveAccruedTakenLabels = "LeaveAccruedTaken";
  const leaveAccruedTakenFacultyLabels = `${leaveAccruedTakenLabels}.facultyDetailsLabels`;
  const leaveAccruedTakenBreadcrumbs = `${leaveAccruedTakenLabels}.breadcrumb`;
  const classes = useStyles();

  const hasServiceRequirementAlert = (facultyDetails) => {
    return (
      !!facultyDetails.fiveYearServiceRequirementMetDate &&
      !!facultyDetails.fiveYearServiceRequirementAlert
    );
  };

  const createServiceRequirementAlertTooltipText = (facultyDetails) => {
    let alerts = facultyDetails.fiveYearServiceRequirementAlert.split(",");

    return alerts
      .map((alert) =>
        t(`${leaveAccruedTakenFacultyLabels}.fiveYrServiceReqAlert.${alert}`)
      )
      .join(" & ");
  };

  const show3YearAverageFte = (facultyDetails) => {
    return (
      facultyDetails.hasPdlAppointment &&
      facultyDetails.fteThreeYearsAverage &&
      serviceRequirementIsMetAndNotInFuture(facultyDetails)
    );
  };

  const serviceRequirementIsMetAndNotInFuture = (facultyDetails) => {
    return (
      !!facultyDetails.fiveYearServiceRequirementMetDate &&
      !isFuture(facultyDetails.fiveYearServiceRequirementMetDate)
    );
  };

  const handleChangeExpandCollapseAllBtn = () => {
    setExpandAllAccrual(!expandAllAccrual);
  };

  const handleChangeLeaveSectionNotesDialog = () => {
    setShowLeaveSectionNotesDialog(!showLeaveSectionNotesDialog);
  };

  const isFuture = (dateStr) => {
    const today = new Date().toISOString().substr(0, 10);
    return dateStr > today;
  };

  /* GET Years in Rank for all faculty categories */
  const getYearsInRank = (facultyDetails) => {
    let getYearsInRank = null;
    if (!facultyDetails.yearsInRank.active) {
      getYearsInRank = t(
        `${leaveAccruedTakenFacultyLabels}.yearsInRankObject.INACTIVE`
      );
    } else if (!facultyDetails.yearsInRank.som) {
      getYearsInRank = t(
        `${leaveAccruedTakenFacultyLabels}.yearsInRankObject.NA`
      );
    } else {
      const fetcheDmonths = facultyDetails.yearsInRank.monthsInRank;
      const years = Math.floor(fetcheDmonths / 12);
      const months = fetcheDmonths % 12;
      getYearsInRank = `${t(
        `${leaveAccruedTakenFacultyLabels}.yearMonthObject.year`,
        {
          count: years,
        }
      )}, ${t(`${leaveAccruedTakenFacultyLabels}.yearMonthObject.month`, {
        count: months,
      })}`;
    }
    return getYearsInRank;
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      classes={{ container: classes.leaveAccruedWrapper }}
    >
      <Grid
        container
        classes={{ container: classes.leaveAccruedBreadcrumbsContainer }}
      >
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link color="inherit" to={`/faculty${location.search}`}>
            <Typography color="textSecondary">
              {t(`${leaveAccruedTakenBreadcrumbs}.facultyListLabel`)}
            </Typography>
          </Link>
          <Typography color="textPrimary">
            {" "}
            {t(`${leaveAccruedTakenBreadcrumbs}.leaveAccruedLabel`)}
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Typography color="textPrimary" variant="h1">
        {t(`${leaveAccruedTakenBreadcrumbs}.leaveAccruedLabel`)}
      </Typography>
      {pageError && alert.exists && (
        <Grid container>
          <ASAlert />
        </Grid>
      )}
      {loading && (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      )}
      {!loading && Object.keys(facultyDetails).length > 0 && (
        <React.Fragment>
          <Paper elevation={3} classes={{ root: classes.paperWidth }}>
            <Typography color="textPrimary" variant="h2">
              {facultyDetails.primaryName}
            </Typography>
            <Grid container item xs={11}>
              <Grid item xs={5}>
                <Grid container item direction="row" xs={10}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.prefName`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {facultyDetails.prefname}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" xs={10}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.universityId`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {facultyDetails.universityId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" xs={10}>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      {t("globals.labels.sunetID")}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {facultyDetails.sunetId}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={(facultyDetails.hasPdlAppointment && 6) || 4}>
                <Grid container item direction="row" xs={11}>
                  <Grid item xs={6}>
                    <Typography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.academicApptDate`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      classes={{ body1: classes.typographySemiBold }}
                    >
                      {facultyDetails.academicApptDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row" xs={11}>
                  <Grid item xs={6}>
                    <Typography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.yearsInRank`)}:{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary">
                      {getYearsInRank(facultyDetails)}
                    </Typography>
                  </Grid>
                </Grid>
                {show3YearAverageFte(facultyDetails) ? (
                  <Grid container item direction="row" xs={11}>
                    <Grid item xs={6}>
                      <Typography color="textPrimary">
                        {t(`${leaveAccruedTakenFacultyLabels}.averageFTE`)}:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        classes={{ body1: classes.typographySemiBold }}
                      >
                        {numberToPercentage(
                          facultyDetails.fteThreeYearsAverage
                        )}
                        <Tooltip
                          title={t(
                            `${leaveAccruedTakenFacultyLabels}.averageFTEHelpText`
                          )}
                        >
                          <HelpIcon
                            color="primary"
                            fontSize="small"
                            viewBox="0 0 22 22"
                            style={{ position: "relative", left: "4px" }}
                          />
                        </Tooltip>
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {facultyDetails.hasPdlAppointment &&
                  facultyDetails.fiveYearServiceRequirementMetDate && (
                    <Grid container item direction="row" xs={11}>
                      <Grid item xs={6}>
                        <Typography color="textPrimary">
                          {t(
                            `${leaveAccruedTakenFacultyLabels}.fiveYrServiceReqMet`
                          )}{" "}
                          :{" "}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          classes={{ body1: classes.typographySemiBold }}
                        >
                          {facultyDetails.fiveYearServiceRequirementMetDate}
                          {hasServiceRequirementAlert(facultyDetails) ? (
                            <Tooltip
                              title={createServiceRequirementAlertTooltipText(
                                facultyDetails
                              )}
                            >
                              <WarningIcon
                                fontSize="small"
                                classes={{ root: classes.warningIcon }}
                                style={{
                                  position: "relative",
                                  top: "4px",
                                  left: "4px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Paper>
          {/* Call to display Current Appointments panel*/}
          <LeaveAccruedTakenAccordion
            children={{
              title: t(
                `${leaveAccruedTakenLabels}.currentApptLabels.currentApptsTitle`
              ),
              summaryDetails: (
                <CurrentAppointments facultyDetails={facultyDetails} />
              ),
              defaultExpanded: true,
            }}
          />

          {/* Call to display Appointment History panel*/}
          {facultyDetails._links.viewFacultyAppointments && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.apptHistoryLabels.apptHistoryTitle`
                ),
                summaryDetails: (
                  <AppointmentHistory
                    universityId={universityId}
                    setPageError={setPageError}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    showLeaveSectionNotesDialog={showLeaveSectionNotesDialog}
                    handleChangeLeaveSectionNotesDialog={
                      handleChangeLeaveSectionNotesDialog
                    }
                    setShowLeaveSectionNotesDialog={
                      setShowLeaveSectionNotesDialog
                    }
                    setLeaveNotesContent={setLeaveNotesContent}
                    setIsLeaveNoteEditable={setIsLeaveNoteEditable}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Appointment Segments panel*/}
          {facultyDetails._links.viewFacultySegments && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.appointmentSegmentsLabels.appointmentSegmentsTitle`
                ),
                contentButton: facultyDetails._links.addMilestone && (
                  <CreateIndividualMilestone
                    universityId={universityId}
                    setReloadAppointmentSegments={setReloadAppointmentSegments}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                  />
                ),
                summaryDetails: (
                  <AppointmentSegments
                    universityId={universityId}
                    reloadAppointmentSegments={reloadAppointmentSegments}
                    setReloadAppointmentSegments={setReloadAppointmentSegments}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                    setPageError={setPageError}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    editMilestone={facultyDetails._links.editMilestone}
                    removeMilestone={facultyDetails._links.removeMilestone}
                    viewMilestone={facultyDetails._links.viewMilestone}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Accrual panel*/}
          {facultyDetails._links.viewFacultyAccruals && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.accrualLabels.accrualTitle`
                ),
                isSubOrdinateBtn: accruals.length !== 0,
                expandAllAccrual: expandAllAccrual,
                setExpandAllAccrual: setExpandAllAccrual,
                handleChangeSubOrdinateBtn: handleChangeExpandCollapseAllBtn,
                subOrdinateBtnText:
                  (!expandAllAccrual &&
                    t("globals.form.actionButtons.expandAll")) ||
                  (expandAllAccrual &&
                    t("globals.form.actionButtons.collapseAll")),
                isButtonEnable: true,
                summaryDetails:
                  (accrualsLoading && (
                    <Grid container justify="center">
                      <CircularProgress />
                    </Grid>
                  )) ||
                  accruals.map((data) => (
                    <LeaveAccruedTakenAccordion
                      children={{
                        title: data.year,
                        defaultExpanded: false,
                        expanded: expandAllAccrual,
                        summaryDetails:
                          data.pdlLeaves || data.sabbaticalLeaves ? (
                            <AccrualSubPanels
                              leavesData={data}
                              setReloadAccrual={setReloadAccrual}
                              setReloadAudit={setReloadAudit}
                              editAccrual={facultyDetails._links.editAccrual}
                            />
                          ) : (
                            <Grid
                              container
                              spacing={5}
                              item
                              xs={3}
                              justify="center"
                            >
                              <Grid item>
                                {t(
                                  `${leaveAccruedTakenLabels}.accrualLabels.noAccrualMessage`
                                )}
                              </Grid>
                            </Grid>
                          ),
                        isSubExpansionPanel: true,
                      }}
                    />
                  )),
                defaultExpanded: false,
                hasSubSectionsExpansion: true,
              }}
            />
          )}
          {/* Call to display Leave panel*/}
          {facultyDetails._links.viewFacultyLeaves && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.leaveHistoryLabels.leaveTitle`
                ),
                contentButton: facultyDetails._links.addLeave && (
                  <CreateEditViewLeaveActions
                    universityId={universityId}
                    setReloadLeave={setReloadLeave}
                    setReloadAudit={setReloadAudit}
                    actionType="add"
                  />
                ),
                isSubOrdinateBtn: true,
                subOrdinateBtnText: t(
                  "globals.form.actionButtons.leaveSectionNotesBtn"
                ),
                handleChangeSubOrdinateBtn: handleChangeLeaveSectionNotesDialog,
                leaveNotes: leaveNotesContent,
                isButtonEnable: isLeaveNoteEditable,
                summaryDetails: (
                  <Leave
                    universityId={universityId}
                    reloadLeave={reloadLeave}
                    setReloadLeave={setReloadLeave}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                    setPageError={setPageError}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Audit panel*/}
          {facultyDetails._links.viewFacultyLeaves && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(`${leaveAccruedTakenLabels}.auditLabels.auditTitle`),
                summaryDetails: (
                  <Audit
                    universityId={universityId}
                    reloadAudit={reloadAudit}
                    setReloadAudit={setReloadAudit}
                    setPageError={setPageError}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                  />
                ),
                defaultExpanded: false,
              }}
            />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};
