import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  showDialogContent: {
    display: "block",
  },
  hideDialogContent: {
    display: "none",
  },
});
