import axios from "axios";
import i18n from "../../src/i18n";

//Service function to Adjust Accruals
export const adjustAccruals = async (
  setReloadAccrual,
  setReloadAudit,
  handleCancel,
  setAlert,
  setShowSaveConfirmationMsg,
  { universityId, values, category }
) => {
  const { effectiveDate, adjustValue, adjustType, notes } = values;
  const data = {
    effectiveDate,
    leaveType: category,
    notes,
    adjustValue,
    adjustType,
  };
  try {
    const response = await axios({
      url: `/faculty/${universityId}/accruals`,
      method: "put",
      data,
    });
    const { accrualAdjusted } = response.data;
    accrualAdjusted && handleCancel();
    accrualAdjusted && setReloadAccrual(true);
    accrualAdjusted && setReloadAudit(true);
  } catch (error) {
    if (error.code === "INVALID_VALUE" && error.status === 400) {
      setAlert(
        "error",
        i18n.t(
          "LeaveAccruedTaken.accrualLabels.accrualAdjustmentDialog.effectiveDateValidationMsg"
        ),
        false,
        false
      );
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setShowSaveConfirmationMsg(false);
  }
};

//Service function to get Beg. Balance
export const getAccrualBegBalance = async (
  setAlert,
  { universityId, effectiveDate, category }
) => {
  try {
    const response = await axios({
      url: `/faculty/${universityId}/accrual-beginning-balance`,
      params: {
        effectiveDate,
        leaveType: category,
      },
    });
    return response.data;
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};
