import React from "react";
import { default as NumberFormat } from "react-number-format";

/* Function to format the Decimal Values,
 * if the length of Decimal digits is greater than 3,
 * then round-off the value to 3 decimal places (keeping the trailing zeros),
 * otherwise return the same value
 */
export const FormatDecimalValues = (value) =>
  (value % 1 !== 0 &&
    value.toString().split(".")[1].length > 3 &&
    value.toFixed(3)) ||
  value;

/* Function to format the Negative Values */
export const FormatNegativeValues = (value, negativeNumberColor) => {
  return (
    <React.Fragment>
      <NumberFormat
        value={
          (value < 0 && Math.abs(FormatDecimalValues(value))) ||
          FormatDecimalValues(value)
        }
        className={(value < 0 && negativeNumberColor) || ""}
        displayType={"text"}
        prefix={value < 0 && "("}
        suffix={value < 0 && ")"}
      />
    </React.Fragment>
  );
};

/* Function to format the Decimal value to convert it into pecentage value */
export const numberToPercentage = (num) => {
  return +(Math.round(num * 100 + "e+3")  + "e-3") + '%';
};
