import axios from "axios";

/** Service to get details/identifiers of Faculty */
export const getFacultyDetails = async (
  setFacultyDetails,
  setLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}`,
    });
    setFacultyDetails(response.data);
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/** Service to get Appointments of Faculty */
export const getAppointmentsHistory = async (
  setAppointments,
  setLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/appointments`,
    });
    if (response.data) {
      setAppointments(response.data);
    }
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/** Service to get Leaves */
export const getLeaves = async (
  setLeaves,
  setLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/leaves`,
    });
    if (response.data.leaves) {
      setLeaves(response.data.leaves);
    }
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/** Service to get Appointment Segments */
export const getAppointmentSegments = async (
  setAppointmentSegments,
  setLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/segments`,
    });
    if (response.data.segments) {
      setAppointmentSegments(response.data.segments);
    }
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/** Service to get Accruals */
export const getAccruals = async (
  setAccruals,
  setAccrualsLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setAccrualsLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/accruals`,
    });
    if (response.data.accruedLeaves) {
      setAccruals(response.data.accruedLeaves);
    }
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setAccrualsLoading(false);
  }
};

/** Service to get Audit */
export const getAudit = async (
  setAuditDetails,
  setLoading,
  setPageError,
  setAlert,
  clearAlert,
  universityId
) => {
  try {
    setLoading(true);
    setPageError(false);
    clearAlert();
    const response = await axios({
      url: `/faculty/${universityId}/changehistory`,
    });
    if (response.data.changeHistory) {
      setAuditDetails(response.data.changeHistory);
    }
  } catch (error) {
    setPageError(true);
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};
