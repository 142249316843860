import { useCallback } from "react";
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from "../constants";
import axios from "axios";

let timeoutId; // ID for API debounce timeout

/* Service to fetch Person Options */
export const useGetPersonOptions = () => {
  const loadAutocomplete = useCallback(
    async (setPersonOptions, setPersonFilterError, filterPersonValue) => {
      clearTimeout(timeoutId); // Cancel current debounce timer

      timeoutId = setTimeout(async () => {
        try {
          setPersonFilterError({
            exist: false,
            message: null,
          });
          const response = await axios({
            url: `/faculty/autocompletes?value=${filterPersonValue}`,
          });
          if (response.data.values) {
            setPersonOptions(response.data.values);
          }
        } catch (error) {
          setPersonFilterError({
            exist: true,
            message: error.message,
          });
        }
      }, AUTOCOMPLETE_DEBOUNCE_DELAY);
    },
    []
  );

  return { loadAutocomplete };
};
