import { ASTheme } from "@stanford-tds/as-components";
import { merge } from "lodash";

const FLAOverrides = {
  MuiTableCell: {
    root: {
      maxWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  MuiTypography: {
    body1: {
      lineHeight: "32.3px",
    },
  },
};

const mergeOverrides = {
  overrides: merge(ASTheme && ASTheme.overrides, FLAOverrides),
};

export const FLATheme = { ...ASTheme, mergeOverrides };
