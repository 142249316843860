import React from "react";
import { Redirect } from "react-router-dom";
import { FacultyLeave } from "../components/FacultyLeave/FacultyLeave";
import { FacultyLeaveFilter } from "../components/FacultyLeave/FacultyLeave.filters";
import { LeaveAccruedTaken } from "../components/LeaveAccruedTaken/LeaveAccruedTaken";
import { FacultyLeaveProvider } from "../services/facultyLeaveContext";
import { ASAccessDenied } from "@stanford-tds/as-components";
import i18n from "../../src/i18n";

export const RoutesList = {
  /** Routes for Faculty */
  faculty: [
    {
      path: "/",
      exact: true,
      title: "FLA.mainView.title",
      filter: null,
      main: <Redirect to="/faculty" />,
      provider: FacultyLeaveProvider,
    },
    {
      path: "/faculty",
      title: "FLA.mainView.title",
      exact: true,
      filter: <FacultyLeaveFilter />,
      main: <FacultyLeave />,
      provider: FacultyLeaveProvider,
    },
    {
      path: "/faculty/:universityId",
      title: null,
      exact: true,
      filter: null,
      main: <LeaveAccruedTaken />,
      provider: FacultyLeaveProvider,
    },
    {
      path: "*",
      title: null,
      exact: false,
      filter: null,
      main: <ASAccessDenied supportEmail={i18n.t("app.supportEmail")} />,
      provider: FacultyLeaveProvider,
    },
  ],
};
