import React from "react";
/** Custom Component **/
import { AccordionSummaryTable } from "./LeaveAccruedTakenAccordion/AccordionSummaryTable";
import { FromDateStrToFLADateStr } from "../../Utils/FormatDateValues";
/** Material UI Imports **/
import { Grid, CircularProgress } from "@material-ui/core";

import { useTranslation } from "react-i18next";
/** Service Hooks **/
import { getAppointmentsHistory } from "../../services/leaveAccrualTakenService";
import { LeaveSectionNotes } from "./LeaveActions/LeaveSectionNotes";

//Function to create the Appointment History panel detail
export const AppointmentHistory = (props) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  //Call to the appointments history service to return the data
  const [appointments, setAppointments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [reloadAppointments, setReloadAppointments] = React.useState(true);
  const {
    universityId,
    setPageError,
    setAlert,
    clearAlert,
    showLeaveSectionNotesDialog,
    handleChangeLeaveSectionNotesDialog,
    setShowLeaveSectionNotesDialog,
  } = props;
  React.useEffect(() => {
    reloadAppointments &&
      getAppointmentsHistory(
        setAppointments,
        setLoading,
        setPageError,
        setAlert,
        clearAlert,
        universityId
      );
    setReloadAppointments(false);
  }, [universityId, setAlert, clearAlert, setPageError, reloadAppointments]);

  // Common string for translation key
  const apptHistoryLabels = "LeaveAccruedTaken.apptHistoryLabels";
  const columnData = [
    {
      id: "rank",
      value: t(`${apptHistoryLabels}.rank`),
      width: "28%",
    },
    {
      id: "line",
      value: t(`${apptHistoryLabels}.line`),
      width: "10%",
    },
    {
      id: "department",
      value: t(`${apptHistoryLabels}.department`),
      tooltip: true,
      width: "20%",
    },
    {
      id: "startDate",
      value: t(`${apptHistoryLabels}.apptStart`),
      width: "12%",
    },
    {
      id: "endDate",
      value: t(`${apptHistoryLabels}.apptEnd`),
      width: "12%",
    },
    {
      id: "apptFte",
      value: t(`${apptHistoryLabels}.fte`),
    },
  ];

  const rowData = [];

  appointments.appointments &&
    appointments.appointments.map((i) =>
      rowData.push({
        rank: { value: i.rank },
        line: { value: i.facultyLine.abbreviation },
        department: { value: i.department && i.department.name },
        startDate: {
          value: FromDateStrToFLADateStr(i.startDate),
        },
        endDate: {
          value: FromDateStrToFLADateStr(i.endDate),
        },
        apptFte: { value: i.apptFte },
      })
    );

  appointments && props.setLeaveNotesContent(appointments.leaveNote || "");
  appointments &&
    props.setIsLeaveNoteEditable(appointments?._links?.editLeaveNote);

  return (
    <React.Fragment>
      {(loading && (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      )) || <AccordionSummaryTable columnData={columnData} rowData={rowData} />}
      <LeaveSectionNotes
        open={showLeaveSectionNotesDialog}
        onClose={handleChangeLeaveSectionNotesDialog}
        editLeaveSectionNotes={
          appointments._links && appointments._links.editLeaveNote
        }
        universityId={universityId}
        setShowLeaveSectionNotesDialog={setShowLeaveSectionNotesDialog}
        setReloadAppointments={setReloadAppointments}
        existingLeaveNote={appointments && appointments.leaveNote}
      />
    </React.Fragment>
  );
};
