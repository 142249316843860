import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import { sendLeaveEmailNotification } from "../../../services/leaveActionService";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { EmailBccValue } from "../../../constants";

function PaperComponent(props) {
  return (
    <Draggable
      cancel="input,textarea,button"
      bounds={{
        top: -(window.innerHeight / 2 + 200),
        bottom: window.innerHeight / 2 + 200,
        right: window.innerWidth / 2 + 200,
        left: -(window.innerWidth / 2 + 200),
      }}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const EmailNotification = (props) => {
  const {
    open,
    onClose,
    emailDetails,
    setReloadLeave,
    universityId,
    actionId,
    setShowEmailNotificationDialog,
  } = props;
  const [toValue, setToValue] = React.useState([]);
  const [ccValue, setCcValue] = React.useState([]);
  const { t } = useTranslation();
  const { alert, setAlert, clearAlert } = useAlertContext();

  React.useEffect(() => {
    if (open) {
      clearAlert();
    }
  }, [open, clearAlert]);

  const handleSubmit = () => {
    sendLeaveEmailNotification(
      universityId,
      actionId,
      toValue,
      ccValue,
      emailDetails,
      setReloadLeave,
      setShowEmailNotificationDialog,
      setAlert
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      disableBackdropClick={true}
      hideBackdrop={true}
      disableScrollLock={true}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DialogTitle id="risk-condition-dialog-title" disableTypography>
        {t(
          "LeaveAccruedTaken.leaveActionForm.emailNotification.emailNotificationTitle"
        )}
      </DialogTitle>
      {alert.exists && <ASAlert />}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="to"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.toLabel"
              )}
              fullWidth
              variant={"outlined"}
              required
              onChange={(event) => {
                setToValue(event.target.value.split(","));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="cc"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.ccLabel"
              )}
              fullWidth
              variant={"outlined"}
              onChange={(event) => {
                setCcValue(event.target.value.split(","));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="bcc"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.bccLabel"
              )}
              fullWidth
              variant={"outlined"}
              value={EmailBccValue}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="subject"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.subjectLabel"
              )}
              fullWidth
              variant={"outlined"}
              value={emailDetails.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="body"
              label={t(
                "LeaveAccruedTaken.leaveActionForm.emailNotification.bodyLabel"
              )}
              multiline
              fullWidth
              variant={"outlined"}
              inputProps={{ maxLength: 2000 }}
              value={emailDetails.body}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={6} justify="space-evenly">
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              fullWidth
            >
              {t("globals.form.actionButtons.dismiss")}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              disabled={toValue.length === 0}
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("globals.form.actionButtons.send")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
