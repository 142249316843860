import React from "react";
import { useStyles } from "./FilterPanel.styles";
import { useTranslation } from "react-i18next";
import { Link, Grid } from "@material-ui/core";
import { Clear as ClearIcon, Refresh as RefreshIcon } from "@material-ui/icons";

export const ResetFilter = ({ resetFn, clearFn }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justify={"flex-end"} className={classes.resetFilter}>
      {/* Reset Filter Container, containing reset interface. */}
      <Grid item xs={6}>
        <ClearIcon className={classes.icon} />
        <Link
          component="button"
          underline="none"
          color="textPrimary"
          onClick={() => clearFn()}
        >
          {t("FLA.filterPanel.clearFilterText")}
        </Link>
      </Grid>
      <Grid item>
        <RefreshIcon className={classes.icon} />
        <Link
          component="button"
          underline="none"
          color="textPrimary"
          /**
           * On click of Reset Filter
           *
           * 1. Calling up the function named resetFilter
           * to reset all of the state values and reloading
           * the data based on that.
           * 2. Definition of reset filter function will
           * vary with different components it belongs to.
           * */
          onClick={() => resetFn()}
        >
          {t("FLA.filterPanel.resetFilterText")}
        </Link>
      </Grid>
    </Grid>
  );
};
