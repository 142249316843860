import React, { useState, useCallback } from "react";
/** Material UI Imports **/
import { Grid } from "@material-ui/core";
import { useStyles } from "./Page.styles";
import { PageTitle } from "../PageTitle/PageTitle";
import { FilterPanel } from "../Layout/FilterPanel/FilterPanel";
import { useHistory } from "react-router-dom";

export const Page = (props) => {
  const [open, setOpen] = useState(true);
  const [filterData, setFilterData] = useState({});

  const classes = useStyles();
  const history = useHistory();

  function handleDrawerToggle() {
    setOpen(!open);
  }
  function resetMobileView(val) {
    setOpen(val);
  }
  const changeFilterData = useCallback((data) => {
    setFilterData(data);
  }, []);
  return (
    <Grid container wrap="nowrap">
      {props.filter ? (
        <Grid item classes={{ item: classes.filterPanelContainer }}>
          <FilterPanel
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            resetMobileView={resetMobileView}
            changeFilterData={changeFilterData}
          >
            {props.filter}
          </FilterPanel>
        </Grid>
      ) : null}
      <Grid
        container
        item
        wrap="nowrap"
        direction="column"
        classes={{ root: classes.pageContentContainer }}
      >
        {props.title ? (
          <Grid item>
            <PageTitle title={props.title} />{" "}
          </Grid>
        ) : null}
        <Grid item>
          {props.main &&
            React.Children.map(props.main, (child) => {
              return React.cloneElement(child, {
                filterData: filterData,
                history: history,
              });
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
