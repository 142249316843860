import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accordionRoot: (props) => ({
    width: "100%",
    marginTop: !props.isSubExpansionPanel && "16px",
    "&.Mui-expanded": {
      marginBottom: !props.isSubExpansionPanel && "0px",
    },
    "&:before": {
      background: "none",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "50px",
    },
    "& .MuiAccordionSummary-root.Mui-focused": {
      background: theme.palette.grey[700],
    },
  }),
  expandIcon: (props) => ({
    order: -1,
    color: props.isSubExpansionPanel
      ? theme.palette.common.black
      : theme.palette.common.white,
  }),
  accordionSummaryRoot: (props) => ({
    background: props.isSubExpansionPanel ? "none" : theme.palette.grey[700],
    minHeight: "50px",
    height: "50px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    "& .MuiTypography-body1": {
      fontWeight: props.isSubExpansionPanel ? "600" : "default",
    },
  }),
  accordionContent: (props) => ({
    justifyContent: "space-between",
    alignItems: "center",
    color: props.isSubExpansionPanel
      ? theme.palette.common.black
      : theme.palette.common.white,
  }),
  accordionDetailsRoot: (props) => ({
    flexDirection: "column",
    padding: props.hasSubSectionsExpansion
      ? "8px 4px"
      : props.isSubExpansionPanel
      ? "8px 16px 0px"
      : "default",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
  }),
  subOrdinateBtn: { marginTop: "8px", marginLeft: "8px", marginBottom: "8px" },
  leaveNotes: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    width: "65%",
  },
}));
