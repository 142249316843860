import { makeStyles } from "@material-ui/core/styles";
import { SegmentsColor } from "../../constants";

export const useStyles = makeStyles((theme) => ({
  leaveAccruedWrapper: {
    marginRight: "16px",
  },
  leaveAccruedBreadcrumbsContainer: {
    "& a": {
      color: "gray",
      cursor: "pointer",
    },
  },
  paperWidth: {
    width: "100%",
    padding: "8px 16px 16px",
    marginTop: "8px",
  },
  typographySemiBold: {
    fontWeight: 600,
  },
  divider: {
    margin: "10px -16px 10px -16px",
  },
  editDeleteSvgIconRoot: {
    cursor: "pointer",
    marginLeft: "5px"
  },
  cropSquareIcon: {
    transform: "rotate(45deg)",
  },
  sabColorIndicatorBar: {
    background: SegmentsColor["SAB_LIGHT"],
    width: "6px",
    margin: "10px",
  },
  pdlColorIndicatorBar: {
    background: SegmentsColor["PDL_LIGHT"],
    width: "6px",
    margin: "10px",
  },
  iconButtons: {
    padding: "0px 12px 0px 0px",
  },
  negativeNumberColor: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginTop: "5px",
  },
}));
