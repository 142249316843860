import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { useStyles } from "./ASConfirmation.styles";

// this is ASConfirmation dialog
export const ASConfirmation = (props) => {
  const {
    open,
    title,
    message,
    okLabel,
    cancelLabel,
    handleCancel,
    handleOk,
    disableTypography,
  } = props;

  // To use the makeStyles
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle
        id="alert-dialog-title"
        disableTypography={disableTypography}
      >
        {title || ""}
      </DialogTitle>
      <DialogContent
        className={
          message ? classes.showDialogContent : classes.hideDialogContent
        }
      >
        <DialogContentText id="alert-dialog-description">
          {message || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelLabel ? cancelLabel : "CANCEL"}
        </Button>
        <Button onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
